import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


//image


// import required modules
import { Keyboard, Navigation } from 'swiper/modules';
import TranslateText from '../../context/TranslateText';
import { Link } from 'react-router-dom';

const SwiperCom = ({data}) => {
  return (
    <div className='outswiper'>
        
        <Swiper
        slidesPerView={1}
        spaceBetween={30}
        keyboard={{
          enabled: true,
        }}
       
        navigation={true}
        modules={[Keyboard, Navigation]}
        className="mySwiper"
      >
        {data.map(item=>(
        <SwiperSlide>
            <div className='grid xl:grid-cols-2 grid-cols-1 gap-20 justify-start p-2 sm:p-0'>
                <div className='inswiper'>
                <Swiper
        slidesPerView={1}
        spaceBetween={30}
        keyboard={{
          enabled: true,
        }}
        
        navigation={true}
        modules={[Keyboard, Navigation]}
        className="mySwiper"
      >
            {item.img.map(c=>(
                <SwiperSlide>
                <div className='w-full xl:h-full h-[400px]  rounded-[20px]' style={{
    background: `url(${c})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }}>
    <p className='text-left p-4 w-full  text-[20px] sm:text-[28px] lg:text-[38px] text-[white] font-manrope capitalize font-[600]'><TranslateText dictionary={item.title} /></p>
                {/* <img src={c} alt="img"  className='w-full  max-h-[628px]'/> */}
                </div>
                </SwiperSlide>
            ))}
            
      
      </Swiper>
                </div>
                <div className='text-left  xl:w-[70%] w-full'>
                    {/* <p className='text-[21px] text-[#7A7A7A] text-left font-manrope font-[500] lowercase'>на человека</p> */}
                    {/* <p className='md:text-[38px] text-[28px] text-[#EE9B00] font-[600] text-left my-3'>$150</p> */}
                    <p className='sm:text-[21px] text-[16px] text-[#3E3C38] leading-[31.5px] font-manrope font-[500] text-left'><TranslateText dictionary={item.description} /></p>
                    <button id='btn' className='mt-[30px] md:mt-[50px]  uppercase 2xl:text-[21px] xl:text-[18px] 2xl:w-[267px] xl:w-[176px] lg:w-[176px] w-[152px] 2xl:h-[51px] xl:h-[45px] h-[37px] border-2 border-solid border-[#EE9B00] hover:bg-[#EE9B00] hover:text-[white] hover:transition-[1s] transition-[1s] text-[#EE9B00] rounded-lg'><Link to={"/payment"}><TranslateText dictionary={{en:"book now", ru:"бронировать", uz:"band qilish"}}/></Link></button>
                </div>
            </div>
        </SwiperSlide>
        ))}
      </Swiper>
      
    </div>
  )
}

export default SwiperCom