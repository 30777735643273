import React, { useEffect, useState } from 'react'
import Aboutpage_1 from "../../assets/about/kabout/kabout_1.png"
import Aboutpage_2 from "../../assets/about/kabout/kabout_2.png"
import Aboutpage_3 from "../../assets/about/kabout/kabout_3.png"
import Aboutpage_4 from "../../assets/about/kabout/kabout_4.png"
import Aboutpage_5 from "../../assets/about/kabout/kabout_5.png"
import Aboutpage_6 from "../../assets/about/kabout/kabout_6.png"
import Aboutpage_7 from "../../assets/about/kabout/kabout_7.png"
import Aboutpage_8 from "../../assets/about/kabout/kabout_8.png"
import Amain from "../../assets/about/kabout/aboutmain.png"
import Amain2 from "../../assets/about/kabout/aminr.png"
import TranslateText from '../../context/TranslateText';

import PagesHeader from "../../components/PagesHeader"
import { Link, useParams } from 'react-router-dom'
import Seohelmet from '../../components/Helmet'


const data = [
  {
    id:1,
    title:{
      en:"A place for inspiration and relaxation",
      ru:"Место для вдохновения и релаксации",
      uz:"Ilhom olish va dam olish uchun joy"
    },
    description:{
      en:"Our hotel perfectly combines comfort, luxury and the ability to combine leisure and work. You can spend time in our beautiful business center, enjoy soft beds and panoramic views from the windows in the rooms, and enjoy first-class service and excellent restaurants. This is the perfect place to relax, where you can fully enjoy every moment.",
      ru:"Наш отель прекрасно сочетает комфорт, роскошь и возможность совмещать отдых и работу. Вы сможете провести время в нашем прекрасном бизнес-центре, наслаждаться мягкими кроватями и панорамными видами из окон в номерах, а также насладиться первоклассным обслуживанием и великолепными ресторанами. Это идеальное место для отдыха, где вы сможете полностью насладиться каждым моментом.",
      uz:"Bizning mehmonxonamiz qulaylik, hashamat va dam olish va ishni birlashtirish imkoniyatini mukammal darajada taqdim etadi. Siz bizning go'zal biznes markazimizda vaqt o'tkazishingiz, xonalardagi derazalardan yumshoq ko'rpa-to'shaklar va panoramali manzaralardan bahramand bo'lishingiz, shuningdek, yuqori darajadagi xizmat va ajoyib restoranlardan bahramand bo'lishingiz mumkin. Bu dam olish uchun eng zo'r joy bo'lib, u yerda siz har lahzadan to'liq bahramand bo'lishingiz mumkin."
    },
    btn:true,
    position:false,
    btntext:{
      en:"book",
      ru:"бронировать",
      uz:"band qilish"
    },
    image1:Aboutpage_1,
    image2:Aboutpage_2,
  },
  {
    id:2,
    title:{
      en:"Hotel with Business Center: The perfect place to relax and work",
      ru:"Отель с Бизнес-Центром: Идеальное место для отдыха и работы",
      uz:"Biznes markazi bo'lgan mehmonxona: dam olish va ishlash uchun ideal joy"
    },
    description:{
      en:"In today's world, where work and leisure are inextricably linked, you need to find the perfect place where you can fully enjoy your vacations without forgetting your business responsibilities. Our hotel with business center provides a unique opportunity to combine excellent rest and impeccable service for business guests and those who simply love comfort.",
      ru:"В современном мире, где работа и отдых неразрывно связаны, нужно найти идеальное место, где можно в полной мере насладиться ваканциями, не забывая о деловых обязанностях. Наш отель с бизнес-центром предоставляет уникальную возможность сочетать отличный отдых и безупречное обслуживание деловым гостям и просто любителям комфорта.",
      uz:"Ish va dam olish bir-biri bilan chambarchas bog'liq bo'lgan zamonaviy dunyoda siz biznes vazifalarini unutmasdan bo'sh joylardan to'liq bahramand bo'lish uchun ideal joyni topishingiz kerak. Bizning biznes markazimiz biznes mehmonlari va shunchaki qulaylik ixlosmandlari uchun ajoyib dam olish va benuqson xizmatni birlashtirish uchun noyob imkoniyatni taqdim etadi."
    },
    btn:true,
    position:true,
    btntext:{
      en:"book",
      ru:"бронировать",
      uz:"band qilish"
    },
    image1:Aboutpage_3,
    image2:Aboutpage_4,
  },
  {
    id:3,
    title:{
      en:"Safety and convenience - parking right next to the hotel",
      ru:"Безопасность и удобство – парковка прямо рядом с отелем",
      uz:"Xavfsizlik va qulaylik-mehmonxona yaqinida to'xtash joyi"
    },
    description:{
      en:"We pride ourselves on providing you with convenient parking right on the hotel grounds. This ensures that your vehicle is safe and secure, and you are free to enjoy your stay with us knowing that a parking space is always at your disposal.",
      ru:"Мы гордимся предоставлением вам удобной парковки прямо на территории отеля. Благодаря этому вы можете быть уверены, что ваш автомобиль находится в безопасности, и вы можете свободно наслаждаться вашим пребыванием у нас, зная, что парковочное место всегда в вашем распоряжении.",
      uz:"Biz sizga to'g'ridan-to'g'ri saytda qulay to'xtash joyini taqdim etishdan faxrlanamiz. Buning yordamida siz mashinangiz xavfsiz ekanligiga amin bo'lishingiz mumkin va to'xtash joyi har doim sizning ixtiyoringizda ekanligini bilib, biz bilan qolishingizdan bemalol bahramand bo'lasiz."
    },
    position:false,
    btn:false,
    image1:Aboutpage_5,
    image2:Aboutpage_6,
  },
  {
    id:4,
    title:{
      en:"Unlock your potential: Join our team",
      ru:"Раскройте свой потенциал: Присоединяйтесь к нашей команде",
      uz:"Potentsialingizni oching: jamoamizga qo'shiling"
    },
    description:{
      en:"We are always happy to see new faces in our friendly team. If you are seeking career advancement and want to work in the hospitality industry, we invite you to consider open positions at our hotel. We value talented and dedicated employees, and provide opportunities to develop professionally and achieve your goals.",
      ru:"В современном мире, где работа и отдых неразрывно связаны, нужно найти идеальное место, где можно в полной мере насладиться ваканциями, не забывая о деловых обязанностях. Наш отель с бизнес-центром предоставляет уникальную возможность сочетать отличный отдых и безупречное обслуживание деловым гостям и просто любителям комфорта.",
      uz:"Biz har doim do'stona jamoamizda yangi yuzlarni ko'rishdan xursandmiz. Agar siz martaba o'sishga intilayotgan bo'lsangiz va mehmondo'st sohada ishlashni xohlasangiz, biz sizni mehmonxonamizdagi ochiq ish joylarini ko'rib chiqishga taklif qilamiz. Biz iste'dodli va sodiq xodimlarni qadrlaymiz va kasbiy rivojlanish va maqsadlaringizga erishish uchun imkoniyatlar yaratamiz."
    },
    btn:true,
    position:true,
    btntext:{
      en:"Contact",
      ru:"связаться",
      uz:"Aloqa"
    },
    image1:Aboutpage_7,
    image2:Aboutpage_8,
  },
  
]

const About = () => {
  const {slug} = useParams()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[slug]);
  const pageHeader = {
    title:{
      en:"about us",
      ru:"о нас",
      uz:"biz haqimizda"
    },
    description:{
      en:"At our hotel, we strive to do everything we can to make your stay exceptional.",
      ru:"В нашем отеле мы стремимся сделать все возможное, чтобы сделать ваше пребывание исключительным.",
      uz:"Mehmonxonamizda biz sizning turar joyingizni ajoyib qilish uchun qo'limizdan kelganini qilishga intilamiz."
    },
    img:windowWidth > 768 ?  Amain : Amain2
  }
  return (
    <div>
        <Seohelmet title={"Kamilla hotel - About"}/>

      <PagesHeader data={pageHeader}/>
    <div className='container'>
      <div className='grid lg:grid-cols-2 grid-cols-1 px-[10px] lg:px-[0px] gap-5 justify-center items-center my-10'>
        {data?.map(item=>(
          <>
          <h2 className='lg:hidden block font-fira-sans text-[#220901] lg:text-[38px] text-[20px] uppercase font-[400]'><TranslateText
          dictionary={item.title}
        /></h2>
          {!(item.position) ? <div className='lg:block hidden'>
          <div className='grid grid-cols-2 gap-1 w-full '>
          <img src={item.image1} alt="image_left"  className='mt-[150px] 2xl:ml-[60px]' />
          <img src={item.image2} alt="image_right"/>
        </div>
        </div> : null }
        <div className='lg:hidden block'>
        <div className='grid sm:grid-cols-2 grid-cols-1 gap-1 w-full m-auto '>
          <div className='sm:h-fit h-[400px]'>
          <img src={item.image1} alt="image_left"  className='md:mt-[150px] mt-[10px] w-full h-full bg-cover bg-center' />

          </div>
          <img src={item.image2} alt="image_right" className='hidden sm:block'/>
        </div>
        </div>
        <div>
          <h2 className='lg:block hidden font-fira-sans text-[#220901] lg:text-[38px] text-[20px] uppercase font-[400] mb-[20px]'><TranslateText
          dictionary={item.title}
        /></h2>
          <p className='font-manrope lg:text-[21px] text-[16px] font-[400] lg:w-[90%] w-full'><TranslateText
          dictionary={item.description}
        /></p>
          {(item.btn) ? <button id='btn' className='lg:mt-10 mt-[20px] uppercase lg:text-[21px] text-[16px] font-[600] lg:w-[267px] lg:h-[51px] w-[152px] h-[36px] border-2 border-solid border-[#EE9B00] hover:bg-[#EE9B00] hover:text-[white] text-[#EE9B00] rounded-lg mb-[40px]'><Link to={"/bron"}><TranslateText
          dictionary={item.btntext}
        /></Link></button>: ""}
        </div>
        {(item.position) ? <div className='lg:block hidden'>
          <div className='grid grid-cols-2 gap-1 w-full '>
          <img src={item.image1} alt="image_left"  className='mt-[150px] 2xl:ml-[60px]' />
          <img src={item.image2} alt="image_right"/>
        </div>
        </div> : null }
        </>
        ))}
      </div>
      <div className='max-w-[1093px] text-center m-auto text-[#3E3C38] px-[10px] lg:mt-[99px] lg:mb-[140px]'>
          <p className='text-[18px] lg:text-[28px] font-[500] font-manrope mb-[50px]'><TranslateText
          dictionary={{
            en:`"We invite you to appreciate the hospitality and quality of service of our hotel, which proudly offers all types of rooms and a successful team ready to create an unforgettable experience for every guest."`,
            ru:"“Мы приглашаем вас оценить гостеприимство и качество обслуживания нашего отеля, который с гордостью предлагает все типы номеров и успешную команду, готовую создать незабываемые впечатления для каждого гостя.”",
            uz:`"Biz sizni mehmonxonamizning mehmondo'stligi va xizmat ko'rsatish sifatini qadrlashga taklif qilamiz, u g'urur bilan barcha turdagi xonalarni va har bir mehmon uchun unutilmas tajriba yaratishga tayyor muvaffaqiyatli jamoani taklif qiladi.”`
          }}
        /></p>
          <div className='flex justify-center items-center text-center'>
            <div>
              <h2 className='lg:text-[51px] text-[28px] font-[600] font-fira-sans'>70</h2>
              <p className='lg:text-[28px] text-[18px] font-manrope capitalize'><TranslateText
          dictionary={{
            en:`Rooms`,
            ru:"Номера",
            uz:`Xonalar`
          }}
        /></p>
            </div>
            <div className='lg:mx-[180px] mx-[30px]'>
              <h2 className='lg:text-[51px] text-[28px] font-[600] font-fira-sans'>25</h2>
              <p className='lg:text-[28px] text-[18px] font-manrope capitalize'><TranslateText
          dictionary={{
            en:`Employees`,
            ru:"сотрудники",
            uz:`Xodimlar`
          }}
        /></p>
            </div>
            <div>
              <h2 className='lg:text-[51px] text-[28px] font-[600] font-fira-sans'>+500</h2>
              <p className='lg:text-[28px] text-[18px] font-manrope capitalize'><TranslateText
          dictionary={{
            en:`Reviews`,
            ru:"отзывы",
            uz:`Sharhlar`
          }}
        /></p>
            </div>
          </div>
      </div>
    </div>
    </div>
  )
}

export default About