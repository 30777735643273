import React, { useEffect } from 'react'
import Date from '../../components/Date'
import PaymentComponent from '../../components/Payment'
import Seohelmet from '../../components/Helmet'

const Payment = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <div className='lg:mt-[140px] mt-[60px]'>
      <Seohelmet title={"Kamilla hotel - Payment"}/>
        <Date/>
        <PaymentComponent/>
    </div>
  )
}

export default Payment