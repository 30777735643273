/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { setModal } from '../../redux/modal.slice';
import { useDispatch, useSelector } from 'react-redux';
const Modal = () => {
  const { modal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  return (
    <div className='z-[10000] flex justify-center items-center w-full h-[100vh] top-0 left-0 fixed bg-[black]/90'>
        <div className='max-w-[1100px] mt-20 px-5 w-full h-full max-h-[500px] lg:max-h-[700px] bg-white '>
            <iframe className='w-full h-full' src={modal.link} frameborder="0"></iframe>
        </div>
        <div className='absolute top-[5%] right-[5%] xl:top-[5%] xl:right-[10%] lg:top-[5%] lg:right-[5%] text-[50px] md:text-[70px] text-[white] cursor-pointer' onClick={()=>dispatch(setModal({open:false,link:""}))}>
            <AiOutlineClose/>
        </div>
    </div>
  )
}

export default Modal