import { configureStore } from "@reduxjs/toolkit";
import navbar from "./navbar.slice";
import modal from "./modal.slice"
import roomtype from "./roomtype.slice"
export const store = configureStore({
  reducer: {
    navbar,
    modal,
    roomtype
  },
  devTools: process.env.NODE_ENV !== "production",
});
