import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import bron_1 from "../../assets/bron/bron_1.png"
import bron_2 from "../../assets/bron/bron_2.png"
import bron_3 from "../../assets/bron/bron_3.png"
import bron_4 from "../../assets/bron/bron_4.png"
import bron_5 from "../../assets/bron/bron_5.png"
import TranslateText from '../../context/TranslateText'
import Seohelmet from '../../components/Helmet'
import {setRoomtype} from "../../redux/roomtype.slice"
import {rooms} from "../../data/rooms"
import {useDispatch, useSelector } from 'react-redux'

const Bron = () => {
 
  const dispatch = useDispatch();
    useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    const navigate = useNavigate()
    const handleClick=(data)=>{
      dispatch(setRoomtype(data))
      navigate("/payment")
    }
    // onClick={()=>handleClick({room:item.title,price_value:item.price_valuta,price:item.price})}
    const data = [
        {
            room_type:{
              en:"Single standard",
              ru:"Одноместный стандарт",
              uz:"Bir kishilik standart"
          },
            img:bron_1,
            cost:{
              en:"45.00 USD / night",
              ru:"45.00 USD / ночь",
              uz:"45.00 USD / kecha",
            }
        },
        {
            room_type:{
              en:"Double standard",
              ru:"Двухместный стандарт",
              uz:"Ikki kishilik standart"
          },
            img:bron_2,
            cost:{
              en:"45.00 USD / night",
              ru:"45.00 USD / ночь",
              uz:"45.00 USD / kecha",
            }
        },
        {
            room_type:{
              en:"Single semi - suite",
              ru:"Одноместный полу - люкс",
              uz:"Bir kishilik Junior Suite"
          },
            img:bron_3,
            cost:{
              en:"45.00 USD / night",
              ru:"45.00 USD / ночь",
              uz:"45.00 USD / kecha",
            }
        },
        {
            room_type:{
              en:"Double semi - deluxe",
              ru:"Двухместный полу - люкс",
              uz:"Ikki kishilik Junior Suite"
          },
            img:bron_4,
            cost:{
              en:"45.00 USD / night",
              ru:"45.00 USD / ночь",
              uz:"45.00 USD / kecha",
            }
        },
        {
            room_type:{
              en:"Lux",
              ru:"Люкс",
              uz:"Lyuks"
          },
            img:bron_5,
            cost:{
              en:"45.00 USD / night",
              ru:"45.00 USD / ночь",
              uz:"45.00 USD / kecha",
            }
        },
    ]
    return (
      <div className='container lg:mt-[160px] sm:mt-[60px] mt-[100px]'>
        <Seohelmet title={"Kamilla hotel - Bron"}/>
        <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-5 md:px-[0px] px-[10px] mt-16 gap-[10px] mb-20'>
          {rooms.map(item=>(
            <div className='mb-10'>
                <div className='relative'>
                    <div className='relative'>
                    <img src={item.img} alt={item.img} className='w-full rounded-[10px] hover:scale-[1.01]  transition-[1s]'/>
                    <p className='xl:text-[28px] md:text-[22px] text-[20px]  absolute  font-manrope capitalize bottom-0 text-[white] h-[70px] left-[0] right-[0] bg-[#161616] bg-opacity-50 p-3'><span><TranslateText dictionary={item.title} /></span></p>
                    </div>
                    <div className='xl:flex justify-between items-center mt-5'>
                      <p className='text-[18px]  font-[500] font-manrope'>{item.price_valuta} <TranslateText dictionary={{en:"soum", ru:"сум", uz:"so'm "}}/> ({item.price}$)<TranslateText dictionary={{
              en:"/ night",
              ru:"/ ночь",
              uz:"/ kecha",
            }} /></p>
                    <button id='btn' className=' uppercase text-[21px] font-manrope xl:max-w-[192px] w-[100%]  md:h-[51px] h-[46px] border-2 border-solid border-[#EE9B00] bg-[#EE9B00] hover:bg-[white]  text-[white] hover:text-[#EE9B00] transition-all rounded-lg' onClick={()=>handleClick({room:item.title,price_value:item.price_valuta,price:item.price})}><TranslateText dictionary={{en:"book", ru:"забронировать", uz:"band qilish"}} /></button>
                    </div>
                </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  
  export default Bron