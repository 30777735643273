/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect,useState } from 'react'
import TranslateText from '../../context/TranslateText'
import Seohelmet from '../../components/Helmet';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';

const Contact = () => {
  const [form, setForm] = useState({
    client_name: "",
    client_email: "",
    client_tel: "",
    payment_type:"visa",
    
})
const updateForm = (e) => {
  setForm ({
      ...form,
      [e.target.name]: e.target.value,
  })
}



  const sendEmail = (e) => {
  
    e.preventDefault();
    emailjs.sendForm('service_uirc7ua', 'template_cyyenho', e.target, 'ri0G-GEIwUHS-IQ_y')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='container px-[10px] lg:mt-[150px] sm:mt-[60px] mt-[100px]'>
      <Seohelmet title={"Kamilla hotel - Contact"}/>
      <div className='flex justify-between items-center my-8 '>
        <div className='md:text-[51px] text-[21px] text-[#3E3C38] font-[400] font-fira-sans uppercase'><TranslateText dictionary={{en:"CONTACT OUR HOTEL", ru:"КОНТАКТ НАШ ОТЕЛЬ", uz:"Mehmonxonamizga murojaat qiling"}} /></div>
        <div><Link target="_blank" to={"https://www.google.com/maps/dir//39W8%2BWXV+KAMILLA,+Ulitsa+Akhunbabayeva,+Navoi+210100,+Uzbekistan/@40.0975238,65.367355,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3f51c6c63f648825:0xda4bfccbb94f19ba!2m2!1d65.3673849!2d40.0974687?entry=ttu"} className='md:flex hidden text-[16px] bg-[#383838] font-[600] font-manrope uppercase  justify-center items-center w-[269px] h-[38px] text-[white]'><TranslateText dictionary={{en:"get directions", ru:"получить направление", uz:"yo'nalishlarni oling"}} /></Link></div>
      </div>
      <div className='md:h-[836px] h-[206px] '>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3126.0422180869496!2d65.3647966387765!3d40.09736089872284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f51c6c63f648825%3A0xda4bfccbb94f19ba!2sKAMILLA!5e0!3m2!1sen!2s!4v1689952995011!5m2!1sen!2s" width="100%" height="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div><br />
      <div><button className='md:hidden text-[16px] bg-[#383838] font-[600] font-manrope uppercase flex justify-center items-center w-[269px] h-[38px] text-[white]'><TranslateText dictionary={{en:"get directions", ru:"получить направление", uz:"yo'nalishlarni oling"}} /></button></div>
      <div className='grid md:grid-cols-4 grid-cols-1 gap-5 mt-10'>
        <div>
          <h1 className='text-[#EE9B00] text-[21px] font-[500] font-manrope '><TranslateText dictionary={{en:"Our address", ru:"Наш адрес", uz:"Bizning manzil"}} /></h1><br />
          <p className='text-[16px] font-[500] font-manrope mb-1 text-[#3E3C38] capitalize'><TranslateText dictionary={{en:"2-2, st. Akhunbabaeva, 210100, Navoi", ru:"2-2 , ул. Ахунбабаева, 210100, г. Навои", uz:"2-2, st. Oxunboboeva, 210100, Navoiy"}} /></p>
          <p className='text-[16px] font-[500] font-manrope text-[#3E3C38] capitalize'>info@hotel-kamilla.uz</p>
        </div>
        <div>
          <h1 className='text-[#EE9B00] text-[21px] font-[500] font-manrope '><TranslateText dictionary={{en:"To the airport", ru:"До аэропорта", uz:"Aeroportga"}} /></h1><br />
          <p className='text-[16px] font-[500] font-manrope text-[#3E3C38] capitalize'><TranslateText dictionary={{en:"by taxi 23 km", ru:"на такси 23 км", uz:"taksi bilan 23 km"}} /></p>
        </div>
        <div>
          <h1 className='text-[#EE9B00] text-[21px] font-[500] font-manrope '><TranslateText dictionary={{en:"To the railway station", ru:"До ж/д вокзала", uz:"Temir yo'l stantsiyasiga"}} /></h1><br />
          <p className='text-[16px] font-[500] font-manrope text-[#3E3C38] capitalize'><TranslateText dictionary={{en:"by taxi 5 km", ru:"на такси 5 км", uz:"taksi bilan 5 km"}} /></p>
        </div>
        <div>
          <h1 className='text-[#EE9B00] text-[21px] font-[500] font-manrope capitalize'><TranslateText dictionary={{en:"city center", ru:"центра города", uz:"shahar markazi"}} /></h1><br />
          <p className='text-[16px] font-[500] font-manrope mb-1 text-[#3E3C38] capitalize'><TranslateText dictionary={{en:"downtown", ru:"в центре города", uz:"shahar markazida"}} /></p>
          <p className='text-[16px] font-[500] font-manrope text-[#3E3C38] capitalize'><TranslateText dictionary={{en:"Nearest attractions National Park named after. A. Navoi", ru:"Ближайшие достопримечательности Национальный парк им. А. Навои", uz:"nomidagi Milliy bog'ning eng yaqin diqqatga sazovor joylari. A. Navoiy"}} /></p>
        </div>
      </div>
      <p className='text-[16px] font-[400] font-manrope text-[#3E3C38] capitalize md:my-20 my-10'><TranslateText dictionary={{en:`Hotel "Camilla" - opened in 2009. Located in the city center, near the Park named after. Alisher Navoi. The train station is a 5-minute drive away, and the airport takes about 25 minutes.`, ru:"Гостиница «Камилла» - открылась в 2009 году. Расположена в центре города, недалеко от Парка им. Алишер Навои. До железнодорожного вокзала можно доехать за 5 минут, а дорога до аэропорта займет около 25 минут.", uz:`"Camilla" mehmonxonasi - 2009 yilda ochilgan. Shahar markazida, nomidagi Park yaqinida joylashgan. Alisher Navoiy. Poezd stantsiyasi 5 daqiqalik masofada joylashgan va aeroportga taxminan 25 daqiqa vaqt ketadi.`}} /></p>
      <div className='grid md:grid-cols-2 grid-cols-1 gap-10'>
      <div className='flex md:hidden  justify-center items-center md:mt-[-200px]'>
          <div className='grid grid-cols-1 justify-center items-center text-center border-2 border-solid border-[black] p-5 outline-2 outline-[black] outline outline-offset-4'>
            <h1 className='text-[63px] font-montecarlo font-[400] text-[#000]'>Kamilla</h1>
            <p className='text-[#220901] font-fira-sans text-[38px] font-[400] uppercase' >hotel</p>
            <p><TranslateText dictionary={{en:"2-2, st. Akhunbabaeva, 210100, Navoi", ru:"2-2 , ул. Ахунбабаева, 210100, г. Навои", uz:"2-2, st. Oxunboboeva, 210100, Navoiy"}} /></p><br />
            <span>(+99879) 223-9997,<br />
(+99879) 223-1473,<br />
(+99879) 223-4515</span><br />
<p>info@hotel-kamilla.uz</p>
          </div>
        </div>
        <div>
        <h1 className='md:text-[51px] text-[20px] text-[#3E3C38] font-[400] font-fira-sans uppercase'><TranslateText dictionary={{en:"YOU HAVE QUESTIONS?", ru:"У ВАС ЕСТЬ ВОПРОСЫ?", uz:"SAVOLLARINGIZ BORMI?"}} /></h1><br />
        <div>
          <div className='w-full mb-4 mt-3'>
            <p className='md:text-[21px] text-[18px] capitalize font-[400] font-manrope'><TranslateText dictionary={{en:"name", ru:"имя", uz:"ism"}} /> <span className='text-[red] ml-1'>*</span></p>
            <input type="text" name="client_name"  className='border-b-2 border-solid border-[#B5B5B5] w-full py-4' />
          </div>
          <div className='w-full mb-4 mt-3'>
            <p className='md:text-[21px] text-[18px] capitalize font-[400] font-manrope'>email <span className='text-[red] ml-1'>*</span></p>
            <input type="email" name="client_email" className='border-b-2 border-solid border-[#B5B5B5] w-full py-4' />
          </div>
          <div className='w-full mb-4 mt-3'>
            <p className='md:text-[21px] text-[18px] capitalize font-[400] font-manrope'><TranslateText dictionary={{en:"Phone", ru:"Телефон", uz:"Telefon"}} /><span className='text-[red] ml-1'>*</span></p>
            <input type="tel" name="client_tel" className='border-b-2 border-solid border-[#B5B5B5] w-full py-4'/>
          </div>
          <div className='w-full mb-4 mt-3'>
            <p className='md:text-[21px] text-[18px] capitalize font-[400] font-manrope'><TranslateText dictionary={{en:"message", ru:"сообщение", uz:"xabar"}} /> <span className='text-[red] ml-1'>*</span> </p>
            <textarea name="" id="" cols="30" rows="7" className='border-b-2 border-solid border-[#B5B5B5] w-full'></textarea>
          </div>
        </div>
        </div>
        <div className='md:flex hidden justify-center items-center md:mt-[-200px] w-full'>
          <div className='grid grid-cols-1 justify-center items-center text-center lg:min-w-[451px] border-2 border-solid border-[black] p-5 outline-2 outline-[black] outline outline-offset-4'>
            <h1 className='text-[63px] font-montecarlo font-[400] text-[#000]'>Kamilla</h1>
            <p className='text-[#220901] font-fira-sans text-[38px] font-[400] uppercase' >hotel</p>
            <p><TranslateText dictionary={{en:"2-2, st. Akhunbabaeva, 210100, Navoi", ru:"2-2 , ул. Ахунбабаева, 210100, г. Навои", uz:"2-2, st. Oxunboboeva, 210100, Navoiy"}} /></p><br />
            <span>(+99879) 223-9997,<br />
(+99879) 223-1473,<br />
(+99879) 223-4515</span><br />
<p>info@hotel-kamilla.uz</p>
          </div>
        </div>
      </div>
      <div className='flex gap-5'>
        <input type="checkbox" name="" id="" className='w-[25px] h-[25px]' />
        <p className='text-[16px] font-[400] font-manrope'><TranslateText dictionary={{en:"I consent to Kamilla Hotel collecting my information through this form.", ru:"Я даю согласие на то, чтобы отель kamilla собирал мои данные через эту форму.", uz:"Kamilla mehmonxonasiga ushbu shakl orqali maʼlumotlarimni toʻplashiga rozilik bildiraman."}} /></p>
      </div><br />
      <button  id="btn" className='mt-10 uppercase text-[21px] md:w-[267px] w-[152px] md:h-[51px] h-[36px] border-2 border-solid border-[#EE9B00] bg-[#EE9B00] hover:bg-[white] text-[white] hover:text-[#EE9B00] rounded-lg'><TranslateText dictionary={{en:"send", ru:"отправить", uz:"Jo'natish"}} /></button>
      <br /><br />
      <br /><br />
    </div>
  )
}

export default Contact