"use client";
import React, { useContext } from "react";
import Select from "react-select";
import FlagUz from "../../assets/images/UZ.png";
import FlagRu from "../../assets/images/ru.png";
import FlagGb from "../../assets/images/GB.png";
import { LanguageContext } from "../../context/lang";

function CustomSelect({ fixedNavbar, className }) {
  const { language, updateLang } = useContext(LanguageContext);
  
    const styles = {
      control: (baseStyles) => ({
        ...baseStyles,
        border: "none",
        outline: "none",
        background: "transparent",
        cursor: "pointer",
        boxShadow: "none",
        "&:hover": {
          border: "none",
        },
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: !fixedNavbar ? "#FFFFFFB2" : "#000000B2",
        padding: 0,
      }),
      singleValue: (provided) => {
        return {
          ...provided,
          paddingLeft: 30,
          color: !fixedNavbar ? "#FFFFFFB2" : "#000000B2",
          "& > span > span": {
            display: !fixedNavbar ? "none" : "inline-block",
          },
        };
      },
      menu: (baseStyles) => ({
        ...baseStyles,
        width: "80px",
      }),
    };
  
    const options = [
      {
        value: "en",
        label: (
          <span className={"flex items-center justify-center gap-2"}>
            <img src={FlagGb} alt="Great Britin" />
            <span className={"text-[0.9rem] text-[black] font-[700] "}>EN</span>
          </span>
        ),
      },
      {
        value: "uz",
        label: (
          <span className={"flex items-center justify-center gap-2"}>
            <img src={FlagUz} alt="Uzbekistan" />
            <span className={"text-[0.9rem] text-[black] font-[700] "}>UZ</span>
          </span>
        ),
      },
      {
        value: "ru",
        label: (
          <span className={"flex items-center justify-center gap-2"}>
            <img src={FlagRu} alt="Uzbekistan" />
            <span className={"text-[0.9rem] text-[black] font-[700] "}>RU</span>
          </span>
        ),
      },
    ];
  
    return (
      <>
        <Select
          styles={styles}
          className={className}
          onChange={(item) => updateLang(item)}
          defaultValue={language}
          components={{
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
          options={options}
        />
      </>
    );
  }
  
  export default CustomSelect;