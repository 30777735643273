import React from 'react'
import { Helmet } from 'react-helmet'

const Seohelmet = ({title}) => {
  return (
    <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Лучший отель в Навои,Навоий шаҳридаги энг яхши меҳмонхона, Navoiy shahridagi eng yaxshi mehmonxona, 
         Гостиница, hotel, navoi, сауна, сопрт, eng zo'r service"/>
        <meta name="keywords" content="hotels,hotel uzbekistan,kamilla navoi,kamilla hotel,
        Navoi mehmonxonalari, mehmonxonalar, Navoiy mehmonxonalari, Navoiy shahar mehmonxonalari, Navoiy shahri mehmonxonalari, Navoiy shahar mexmonxonalari, Navoiy shahri mexmonxonalari,
        Navoi mehmonxona, Navoiy mehmonxona, Navoiy mexmonxona, Navoi mexmonxona, Navoi hotels, hotels, Navoi hotels, Navoi city hotels, Navoi city hotels,
        Navoi Hotel, Navoi Hotel, Navoi Hotel, Navoi Hotel, Гостиницы Навои, гостиницы, Гостиницы Навои, Гостиницы города Навои, Гостиницы города Навои,
        Гостиница Навои, Гостиница Навои, Гостиница Навои, Гостиница Навои, 
        navai,navoi hotel,hotel kamilla, kamilla mehmoxona, hotels in navoi"/>
        <title>{title}</title>
        <link rel="canonical" href="https://hotel-kamilla.uz" />
        <meta property="og:site_name" content="hotel-kamilla.uz"/>
        <meta property="og:type" content="website"/>
    </Helmet>
  )
}

export default Seohelmet