import { createContext, useState } from "react";
import FlagGb from "../assets/images/GB.png";
export const LanguageContext = createContext();
export const LanguageConsumer = LanguageContext.Consumer;

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState({
    value: "en",
    label: (
      <span className={"flex items-center justify-center gap-2"}>
        <img src={FlagGb} alt="Great Britin" />
      </span>
    ),
  });

  const updateLang = (e) => {
    console.log(e.label);
    setLanguage({ value: e.value, label: e.label });
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        updateLang,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider };
