import React, { useEffect } from 'react'
import Icon_1 from "../../assets/rooms/Icon_1.png"
import Icon_2 from "../../assets/rooms/Icon_2.png"
import Icon_3 from "../../assets/rooms/Icon_3.png"
import Icon_4 from "../../assets/rooms/Icon_4.png"
import Virtual from "../../assets/images/viirtual.png"
import { rooms } from '../../data/rooms'
import { useNavigate, useParams } from 'react-router-dom'
import { AnotherRoooms, ImageGallery } from '../../pages/Rooms'
import { useDispatch, useSelector } from 'react-redux'
import { setModal } from '../../redux/modal.slice'
import Modal from '../Modal'
import TranslateText from '../../context/TranslateText'
const Roomtype = () => {
    const navigate = useNavigate()
    const {slug} = useParams()
    const data = rooms.find(c=>c.id === Number(slug))
    const anroom = rooms.filter(c=>c.id !== Number(slug))
    const { modal } = useSelector((state) => state.modal);
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, [slug]);
  return (
    <div className='xl:mt-[90px] mt-[60px]'>
         {modal.open? <Modal/> : "" }
        <p className='w-full h-[2px] bg-border-color'></p>
        <div className='xl:flex block h-full xl:max-h-[750px]'>
      <div className='xl:block  xl:w-[40%] w-full 2xl:w-[50%] relative'>
      <img src={Virtual} alt="Virtual" className='hover:scale-[1.5] transition-all absolute translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] cursor-pointer' onClick={()=>dispatch(setModal({open:true,link:data.link}))}/>
        <img src={data.img} alt="" className='w-full h-full max-h-[600px]'/>
      </div>
      <div className='xl:w-[60%] w-full bg-[#383838] lg:p-10 p-3'>
        <h1 className='uppercase font-[400] 2xl:text-[51px] xl:text-[38px] text-[28px] font-fira-sans text-[white] md:mt-0 mt-4'><TranslateText dictionary={data.title} /></h1>
        <div className='flex gap-5 font-manrope font-[600] text-[white]'>
          <p className='lg:text-[28px] lg:14px'><TranslateText dictionary={{en:"from", ru:"от", uz:""}} /></p>
          <p className='2xl:text-[67px] xl:text-[51px] text-[32px]'>{data.price_valuta} <TranslateText dictionary={{en:"soum", ru:"сум", uz:"so'm "}}/> ({data.price}$) <TranslateText dictionary={{en:"", ru:"", uz:" dan"}} /></p>
          <p className='lg:text-[28px]'></p>
        </div>
        <div className='grid lg:grid-cols-2 grid-cols-1  lg:gap-20 gap-10'>
          <div className='text-[#C5C5C5] mt-5'>
          <TranslateText dictionary={data.description} />
          <br /><br />
          <button onClick={() => {navigate("/payment")}} className='lg:block hidden text-[21px] mt-10 font-[600] text-center uppercase text-[white] border-2 border-solid border-[#fff] hover:bg-[white] hover:text-[#383838] px-10 py-2 rounded'><TranslateText dictionary={{en:"book now", ru:"Забронируйте сейчас", uz:"Hoziroq band qiling"}} /></button>
          </div>
          <div>
            <div className='flex gap-5 mb-2'><img src={Icon_1} alt="Icon_1" /> <p className='mt-5 lg:text-[21px] text-[12px] text-[white]'><TranslateText dictionary={{en:"Double bed", ru:"Двойная кровать", uz:"Ikki kishilik karavot"}} /></p></div>
            <div className='flex gap-3 mb-2'><div className='flex justify-start items-end'>
            <img src={Icon_2} alt="Icon_2" className='flex-shrink'/>
            <img src={Icon_2} alt="Icon_2" className=' h-[60%] flex-shrink ml-[-15px]' /></div> <p className='mt-5 ml-[-15px] lg:text-[21px] text-[12px] text-[white]'><TranslateText dictionary={{en:"2 adults 1 child", ru:"2 взрослых 1 ребенок", uz:"2 kattalar 1 bola"}} /></p></div>
            <div className='flex gap-5 mb-2'><img src={Icon_3} alt="Icon_3" /> <p className='mt-5 lg:text-[21px] text-[12px] text-[white]'>30 m<sup>2</sup></p></div>
            <div className='flex gap-5 mb-2'><img src={Icon_4} alt="Icon_4" /> <p className='mt-5 lg:text-[21px] text-[12px] text-[white]'><TranslateText dictionary={{en:"Bath", ru:"Ванна", uz:"Vanna"}} /></p></div>
          </div>
          <button onClick={() => {navigate("/payment")}} className='lg:hidden block text-[21px] lg:mt-10 mt-1 font-[600] text-center uppercase text-[white] border-2 border-solid border-[#fff] hover:bg-[white] hover:text-[#383838] px-10 py-2 rounded-[10px]'><TranslateText dictionary={{en:"book now", ru:"Забронируйте сейчас", uz:"Hoziroq band qiling"}} /></button>
        </div>
      </div>
    </div>
    <ImageGallery Imglist={data.img_list}/>
    <AnotherRoooms data={anroom}/>
    </div>
  )
}

export default Roomtype