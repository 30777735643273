import React, { useEffect } from 'react'
//assets
import Blog from "../../assets/main/news_kam.png"
import { Link } from 'react-router-dom'
import TranslateText from '../../context/TranslateText'
import Seohelmet from '../../components/Helmet'
const data = [
  {
    title:{
      en:"Why Kamilla Hotel?",
      ru:"Почему отель Камилла?",
      uz:"Nega Kamilla mehmonxonasi?"
    },
    img:Blog,
    description:{
      en:"Kamilla Hotel is an excellent choice for guests who want to enjoy a comfortable and relaxing stay in Navoi. The hotel offers a range of amenities, including a swimming pool, sauna, delicious food, laundry services, and friendly staff.",
      ru:"Гостиница Камилла – отличный выбор для гостей, желающих насладиться комфортным и спокойным отдыхом в Навои. Отель предлагает широкий спектр удобств, включая бассейн, сауну, вкусную еду, услуги прачечной и дружелюбный персонал.",
      uz:"Kamilla mehmonxonasi Navoiy shahrida qulay va tasalli dam olishni istagan mehmonlar uchun ajoyib tanlovdir. Mehmonxonada suzish havzasi, sauna, mazali taomlar, kir yuvish xizmatlari va samimiy xodimlar kabi bir qator qulayliklar mavjud."
    },
    month:{
      en:"November",
      ru:"ноябрь",
      uz:"Noyabr"
    },
    day:"03",
    year:"2023"
  }
]
const News = () => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='container lg:mt-[160px] sm:mt-[60px] mt-[20px]'>
      <Seohelmet title={"Kamilla hotel - News"}/>

      <div className='uppercase md:px-[0px] px-[10px] text-[#220901] font-fira-sans lg:text-[51px] text-[28px] font-[400] lg:my-14 my-5'>
    <TranslateText
          dictionary={{
            en:"Blog",
            ru:"блог",
            uz:"Blog"
          }}
        />
      </div>
      <div className='grid lg:grid-cols-3 grid-cols-1 gap-10 md:px-[0px] px-[10px] mb-20'>
        <div className='lg:col-span-2 col-span-1 grid lg:grid-cols-3 rid-cols-1 gap-3 justify-center items-center'>
          {data.map(item=>(
            <>
          <div>
            <img src={item.img} alt="Blog"  className='w-full'/>
          </div>
          <div className='lg:col-span-2 col-span-1'>
            <div className='flex gap-5 text-[#7A7A7A] font-manrope lg:text-[16px] text-[12px] uppercase font-[600]'>
                <Link><TranslateText
          dictionary={{
            en:"luxury",
            ru:"роскошь",
            uz:"hashamat"
          }}
        /></Link>
                <Link><TranslateText
          dictionary={{
            en:"travel",
            ru:"путешествовать",
            uz:"sayohat"
          }}
        /></Link>
                <Link><TranslateText
          dictionary={{
            en:"vacation",
            ru:"отпуск",
            uz:"dam olish"
          }}
        /></Link>
            </div>
            <h3 className='text-[#3E3C38] font-manrope lg:text-[21px] text-[18px] font-[600] mt-1'><TranslateText
          dictionary={item.title}
        /></h3>
            <p className='text-[#3E3C38] font-manrope lg:text-[16px] text-[14px] font-[400] my-2'><TranslateText
          dictionary={{
            en:"Kamilla Hotel is an excellent choice for guests who want to enjoy a comfortable and relaxing stay in Navoi. The hotel offers a range of amenities, including a swimming pool, sauna, delicious food, laundry services, and friendly staff.",
            ru:"Гостиница Камилла – отличный выбор для гостей, желающих насладиться комфортным и спокойным отдыхом в Навои. Отель предлагает широкий спектр удобств, включая бассейн, сауну, вкусную еду, услуги прачечной и дружелюбный персонал.",
            uz:"Kamilla mehmonxonasi Navoiy shahrida qulay va tasalli dam olishni istagan mehmonlar uchun ajoyib tanlovdir. Mehmonxonada suzish havzasi, sauna, mazali taomlar, kir yuvish xizmatlari va samimiy xodimlar kabi bir qator qulayliklar mavjud."
          }}
        /></p>
            <p className='text-[#3E3C38] font-manrope text-[16px] font-[600] capitalize'><TranslateText
          dictionary={item.month}
        /> {item.day}, {item.year}</p>
          </div>
            </>
          ))}
          
        </div>
        
        <div>
        <div className='sticky top-5'>
          <h2 className='uppercase font-[500] lg:text-[28px] text-[22px] font-manrope tracking-[1.4px] text-[#3E3C38]'>
          <TranslateText
          dictionary={{
            en:"Newsletter",
            ru:"Новостная рассылка",
            uz:"Axborot byulleteni"
          }}
        /></h2>
          <input type="email" placeholder="ваш Email" className='w-[80%] my-5 bg-[transparent] border-b-2 border-solid border-[#B7B7B7] py-3 capitalize text-[16px]'/> <br />
          <button className='text-[16px] uppercase px-6 py-2  border-2 border-solid border-[#B7B7B7] bg-[#383838] hover:bg-[#fff] text-[#fff] hover:text-[#383838] font-[600]'><TranslateText
          dictionary={{
            en:"subscribe",
            ru:"подписаться",
            uz:"obuna bo'ling"
          }}
        /></button>

        </div>
        </div>
        
      </div>
    </div>
  )
}

export default News