import React, { useEffect } from 'react'
import About_2 from "../../assets/service/kservice/kservice_1.png"
import About_3 from "../../assets/service/kservice/kservice_2.png"
import About_4 from "../../assets/service/kservice/kservice_3.png"
import About_5 from "../../assets/service/kservice/kservice_4.png"
import About_6 from "../../assets/service/kservice/kservice_5.png"
import About_7 from "../../assets/service/kservice/kservice_6.png"
import About_8 from "../../assets/service/kservice/kservice_7.png"
import About_9 from "../../assets/service/kservice/kservice_8.png"
import About_10 from "../../assets/service/kservice/kservice_9.png"
import About_11 from "../../assets/service/kservice/kservice_10.png"
import About_12 from "../../assets/service/kservice/kservice_11.png"
import About_13 from "../../assets/service/kservice/kservice_12.png"
import About_14 from "../../assets/service/kservice/kservice_13.png"
import About_15 from "../../assets/service/kservice/kservice_14.png"
import Smain from "../../assets/service/kservice/kmain.png"
import PagesHeader from '../../components/PagesHeader'
import TranslateText from '../../context/TranslateText';
import { Link } from 'react-router-dom'
import Seohelmet from '../../components/Helmet'
const data = [
  {
    id:1,
    title:{
      en:"Accommodation in comfort",
      ru:"Проживание в комфорте",
      uz:"Qulaylikda yashash"
    },
    description:{
      en:"We have a variety of rooms, from standard to deluxe, to meet the needs of every guest. All our rooms are equipped with modern amenities and stylishly decorated to create a cozy atmosphere.",
      ru:"Мы имеем разнообразие номеров, от стандартных до люксовых, чтобы удовлетворить потребности каждого гостя. Все наши номера оборудованы современными удобствами и стильно оформлены, чтобы создать атмосферу уюта.",
      uz:"Bizda har bir mehmonning ehtiyojlarini qondirish uchun standartdan hashamatli xonalarga qadar turli xil xonalar mavjud. Bizning barcha xonalarimiz zamonaviy qulayliklar bilan jihozlangan va qulay muhit yaratish uchun zamonaviy tarzda bezatilgan."
    },
    btn:true,
    image_left:About_2,
    image_right:About_3,
  },
  {
    id:2,
    title:{
      en:"Restaurant and Cafe",
      ru:"Ресторан и кафе",
      uz:"Restoran va kafe"
    },
    description:{
      en:"Our restaurant offers a variety of local and international cuisine to satisfy the most refined taste. We also have a cozy cafe where you can enjoy freshly prepared coffee and light snacks.",
      ru:"Наш ресторан предлагает разнообразные блюда местной и международной кухни, чтобы удовлетворить самый изысканный вкус. У нас также есть уютное кафе, где вы можете насладиться свежеприготовленным кофе и легкими закусками.",
      uz:"Bizning restoranimiz eng nozik ta'mni qondirish uchun turli xil mahalliy va xalqaro taomlarni taklif etadi. Shuningdek, bizda yangi tayyorlangan qahva va yengil gazaklardan bahramand bo'lishingiz mumkin bo'lgan qulay kafe mavjud."
    },
    btn:false,
    image_left:About_4,
    image_right:About_5,
  },
  {
    id:3,
    title:{
      en:"Free Wi-Fi",
      ru:"Бесплатный Wi-Fi",
      uz:"Bepul Wi-Fi"
    },
    description:{
      en:"Our entire hotel is covered with free high-speed Wi-Fi so you can stay connected and connected to the outside world.",
      ru:"Мы имеем разнообразие номеров, от стандартных до люксовых, чтобы удовлетворить потребности каждого гостя. Все наши номера оборудованы современными удобствами и стильно оформлены, чтобы создать атмосферу уюта.",
      uz:"Bizning butun mehmonxonamiz bepul yuqori tezlikdagi Wi-fi bilan taminlangan, shuning uchun siz dunyoning istalgan burchagi bilan bog'lanib, aloqada bo'lishingiz mumkin."
    },
    btn:true,
    image_left:About_6,
    image_right:About_7,
  },
  {
    id:4,
    title:{
      en:"Conference Rooms",
      ru:"Конференц-залы",
      uz:"Konferentsiya xonalari"
    },
    description:{
      en:"If you have business meetings or events, our spacious and well-equipped conference and meeting rooms are ideal for successful business meetings and events.",
      ru:"Если у вас есть деловые встречи или мероприятия, наши просторные и хорошо оборудованные конференц-залы и залы для заседаний идеально подойдут для проведения успешных деловых встреч и мероприятий.",
      uz:"Agar sizda ish uchrashuvlari yoki tadbirlaringiz bo'lsa, bizning keng va yaxshi jihozlangan yig'ilish xonalarimiz muvaffaqiyatli ish uchrashuvlari va tadbirlarini o'tkazish uchun juda mos keladi."
    },
    btn:false,
    image_left:About_8,
    image_right:About_9,
  },
  {
    id:5,
    title:{
      en:"Pool and Spa Center",
      ru:"Бассейн и спа-центр",
      uz:"basseyn va spa center"
    },
    description:{
      en:"Our hotel has a luxurious swimming pool for relaxation and recreation, as well as a spa where you can enjoy a variety of treatments for health and beauty.",
      ru:"Мы имеем разнообразие номеров, от стандартных до люксовых, чтобы удовлетворить потребности каждого гостя. Все наши номера оборудованы современными удобствами и стильно оформлены, чтобы создать атмосферу уюта.",
      uz:"Bizning mehmonxonamizda dam olish uchun hashamatli basseyn, shuningdek, turli xil sog'liq va go'zallik muolajalaridan bahramand bo'lishingiz mumkin bo'lgan spa center mavjud."
    },
    btn:true,
    image_left:About_10,
    image_right:About_11,
  },
  {
    id:6,
    title:{
      en:"24-hour front desk and guest services",
      ru:"Круглосуточная стойка регистрации и обслуживания гостей",
      uz:"24 soatlik qabul va mehmonlarga xizmat ko'rsatish"
    },
    description:{
      en:"Our friendly staff is available to assist you at any time of the day or night, providing a high level of service and concern for your comfort.",
      ru:"Наш ресторан предлагает разнообразные блюда местной и международной кухни, чтобы удовлетворить самый изысканный вкус. У нас также есть уютное кафе, где вы можете насладиться свежеприготовленным кофе и легкими закусками.",
      uz:"Bizning samimiy xodimlarimiz sizga kun yoki tunning istalgan vaqtida yordam berishga tayyor bo'lib, sizning qulayligingiz uchun yuqori darajadagi xizmat va g'amxo'rlikni ta'minlaydi."
    },
    btn:false,
    image_left:About_12,
    image_right:About_13,
  },
  {
    id:7,
    title:{
      en:"Laundry Services",
      ru:"Услуги прачечной",
      uz:"Kir yuvish xizmatlari"
    },
    description:{
      en:"We offer laundry and dry cleaning services to make your stay more convenient and comfortable.",
      ru:"Мы предлагаем услуги прачечной и химчистки, чтобы сделать ваше пребывание более удобным и комфортным.",
      uz:"Turar joyingizni yanada qulay qilish uchun kir yuvish va quruq tozalash xizmatlarini taklif etamiz."
    },
    btn:true,
    image_left:About_14,
    image_right:About_15,
  },
]
const Service = () => {
  const pageHeader = {
    title:{
      en:"services",
      ru:"услуги",
      uz:"xizmatlar"
    },
    description:{
      en:"We pride ourselves on offering a wide range of first class services",
      ru:"Мы гордимся тем, что предлагаем широкий спектр первоклассных услуг",
      uz:"Biz yuqori darajadagi xizmatlarning keng assortimentini taklif qilishdan faxrlanamiz"
    },
    img:Smain
  }
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div>
      <Seohelmet title={"Kamilla hotel - Service"}/>
      <PagesHeader data={pageHeader}/>
    <div className='container'>
      <div className='lg:block hidden'>
      <div className='grid grid-cols-2 lg:gap-20 gap-10 justify-center items-center my-10'>
        {data?.map(item=>(
          <>
          {(item.btn) ? <div>
        <div className='grid grid-cols-2 gap-3 '>
          <img src={item.image_left} alt="image_left"  className='mt-[150px] w-full' />
          <img src={item.image_right} alt="image_right" className='w-full'/>
        </div>
        </div> : null }
        
        <div>
          <h2 className='font-fira-sans text-[#220901] text-[38px] uppercase font-[400]'>
          <TranslateText dictionary={item.title}/></h2>
          <p className='font-manrope text-[21px] font-[400] w-[90%] my-8'><TranslateText dictionary={item.description}/></p>
          {(item.btn) ? <button id='btn' className='mt-10 uppercase text-[21px] w-[267px] h-[51px] border-2 border-solid border-[#EE9B00]  hover:bg-[#EE9B00] hover:text-[white] hover:transition-[1s] transition-[1s] text-[#EE9B00] rounded-lg'><Link to={"/bron"}><TranslateText dictionary={{
      en:"book",
      ru:"бронировать",
      uz:"band qilish"
    }}/></Link></button>: ""}
        </div>
        {!(item.btn) ? <div>
        <div className='grid grid-cols-2 gap-3 '>
          <img src={item.image_left} alt="image_left" className='w-full'/>
          <img src={item.image_right} alt="image_right" className='mt-[150px] w-full'/>
        </div>
        </div> : null }
        </>
        ))}
      </div>
      </div>
      <div className='lg:hidden block mt-6'>
      
      {data?.map(item=>(
      <div className='px-[10px]'>
      <h2 className='font-fira-sans text-[#220901] text-[20px] uppercase font-[400]'><TranslateText dictionary={item.title}/></h2>
      <div className='grid grid-cols-2 gap-1 w-full justify-center items-center -mt-8'>
          <img src={item.image_left} alt="image_left" className='w-full'/>
          <img src={item.image_right} alt="image_right" className='mt-[150px] w-full'/>
        </div>
        <p className='font-manrope text-[16px] font-[400] w-full mt-8 mb-4'><TranslateText dictionary={item.description}/></p>
        {(item.btn) ? <button id="btn" className='mt-2 mb-[40px] uppercase text-[16px] w-[152px] h-[36px] border-2 border-solid border-[#EE9B00] hover:bg-[#EE9B00] hover:text-[white] hover:transition-[1s] transition-[1s] text-[#EE9B00] rounded-lg'><Link to={"/bron"}><TranslateText dictionary={{
      en:"book",
      ru:"бронировать",
      uz:"band qilish"
    }}/></Link></button>: ""}
      </div>
      ))}
      </div>
    </div>
    </div>
  )
}

export default Service