import React from 'react'
import human from "../../assets/images/icons/human.svg"
import TranslateText from '../../context/TranslateText'
const Date = () => {
  return (
    <div className='container flex justify-center items-center'>
        <div className='mx-2 bg-[#383838] m-auto rounded-[10px] mt-[20px] py-5 lg:flex grid sm:grid-cols-2 grid-cols-1 gap-10 px-10  justify-center items-center max-w-[885px]'>
            <div className='relative'>
                <p className='text-[21px] font-manrope font-[500] capitalize text-normal mb-[24px] text-[#DEDEDE]'><TranslateText dictionary={{en:"check-in", ru:"заезд", uz:"belgilanish"}} /></p>
                <input type="date" id='inputdate' className='bg-[#383838] px-[50px] text-[21px] text-[white] font-[600] font-manrope' />
            </div>
            <div className='relative'>
                <p className='text-[21px] font-manrope font-[500] capitalize text-normal text-[#DEDEDE] mb-[24px]'><TranslateText dictionary={{en:"departure", ru:"выезд", uz:"ketish"}} /></p>
                <input type="date" id='inputdate' className='bg-[#383838] px-[50px] text-[21px] text-[white] font-[600] font-manrope' />
            </div>
            <div className='relative'>
                <p className='text-[21px] font-manrope font-[500] capitalize text-normal mb-[24px] text-[#DEDEDE]'><TranslateText dictionary={{en:"guests", ru:"гостей", uz:"mehmonlar"}} /></p>

                <div className='flex'>
                    <img src={human} alt="human" />
                <input type="number" min={1} placeholder={1} className='bg-[#383838] px-[25px] text-[21px] text-[#DEDEDE] font-[600] font-manrope w-[100px]' />
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Date