import React, { useRef, useState } from 'react'
import payme from "../../assets/images/icons/payme.png"
import visa from "../../assets/images/icons/visa.png"
import TranslateText from '../../context/TranslateText'
import { useSelector } from 'react-redux'
import emailjs from '@emailjs/browser';

const Payment = () => {
  const { roomtype } = useSelector((state) => state.roomtype);
  const [selected, setSelected] = useState("visa");
  const [form, setForm] = useState({
    client_name: "",
    client_email: "",
    client_tel: "",
    payment_type:"visa",
    
})
const updateForm = (e) => {
  setForm ({
      ...form,
      [e.target.name]: e.target.value,
  })
}

const options = ['payme', 'visa', 'free'];
  const  checkBox = (i) =>{
    setSelected(i)
  }

  const sendEmail = (e) => {
    setForm ({
      ...form,
      payment_type: selected,
  })
    e.preventDefault();
    emailjs.sendForm('service_uirc7ua', 'template_cyyenho', e.target, 'ri0G-GEIwUHS-IQ_y')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };
  return (
    <form className='container grid lg:grid-cols-2 grid-cols-1 px-4 gap-32 my-20'  onSubmit = { sendEmail }>
         <div>
        <p className='md:text-[28px] text-[21px] text-[#3E3C38] font-[500] font-manrope'><TranslateText dictionary={{en:"Guest information", ru:"Информация о гостях", uz:"Mehmonlar haqida ma'lumot"}} /></p><br />
        <div>
          <div className='w-full lg:mb-4 mb-1 mt-3'>
            <p className='md:text-[21px] text-[18px] capitalize font-[400] font-manrope'><TranslateText dictionary={{en:"name", ru:"имя", uz:"ism"}} /> <span className='text-[red]'>*</span></p>
            <input type="text" className='border-b-2 border-solid border-[#B5B5B5] w-full py-4' name='client_name' onChange ={updateForm} required/>
          </div>
          <div className='w-full mb-4 mt-3'>
            <p className='md:text-[21px] text-[18px] capitalize font-[400] font-manrope'>email <span className='text-[red]'>*</span></p>
            <input type="email" className='border-b-2 border-solid border-[#B5B5B5] w-full py-4' name='client_email' onChange ={updateForm} required/>
          </div>
          <div className='w-full mb-4 mt-3'>
            <p className='md:text-[21px] text-[18px] capitalize font-[400] font-manrope'><TranslateText dictionary={{en:"Phone", ru:"Телефон", uz:"Telefon"}} /> <span className='text-[red]'>*</span></p>
            <input type="tel"  className='border-b-2 border-solid border-[#B5B5B5] w-full py-4' name='client_tel' onChange ={updateForm} required/>
          </div>
          <div className='w-full mb-4 mt-3'>
            <p className='md:text-[21px] text-[18px] capitalize font-[400] font-manrope'><TranslateText dictionary={{en:"Additional Information", ru:"Дополнительная информация", uz:"Qo'shimcha ma'lumot"}} /> <span className='text-[red]'>*</span> </p>
            <textarea name="message" id="" cols="30" rows="7" className='p-3 border-b-2 border-solid border-[#B5B5B5] w-full' onChange ={updateForm} required></textarea>
          </div>
      <button type='submit' className='lg:block hidden lg:mt-10 mt-0 uppercase text-[21px] md:w-[300px] w-[202px] md:h-[51px] h-[40px] border-2 border-solid border-[#EE9B00] bg-[#EE9B00] hover:bg-[white] text-[white] hover:text-[#EE9B00] rounded-lg'><TranslateText dictionary={{en:"book", ru:"забронировать", uz:"band qilish"}} /></button>

        </div>
        </div>
        <div>
        <p className='md:text-[28px] text-[21px] text-[#3E3C38] mb-3 font-[500] font-manrope'><TranslateText dictionary={{en:"Select payment system", ru:"Выберите платежную систему", uz:"To'lov tizimini tanlang"}} /></p><br />
        <div>
        <div className='flex gap-10'>
            <input type="radio" name='payment' className='w-[21px] h-[21px]' onChange={() => checkBox(options[0])}/>
            <img src={payme} alt="payme" />
        </div>
        <div className='flex gap-10  my-14'>
            <input type="radio" name='payment' className='w-[21px] h-[21px]' onChange={() => checkBox(options[1])}/>
            <img src={visa} alt="visa" />
        </div>
        <div className='flex gap-10'>
            <input type="radio" name='payment' className='w-[21px] h-[21px]' onChange={() => checkBox(options[2])}/>
            <label className='text-[21px] font-[400] text-[#3E3C38]'><TranslateText dictionary={{en:"visa booking", ru:"Бесплатное бронирование", uz:"Bepul bron qilish"}} /></label>
        </div>
        </div>
        <div className='mt-32'>
            <span className='text-[21px] font-[400] text-[#3E3C38] '><TranslateText dictionary={{en:"Selected room:", ru:"Выбранная комната:", uz:"Tanlangan xona:"}} /></span>
            <p className='text-[28px] font-[600] text-[#3E3C38]'><TranslateText dictionary={roomtype.room} /></p><br />
            <span className='text-[21px] font-[400] text-[#3E3C38] '><TranslateText dictionary={{en:"Total room cost:", ru:"Общая стоимость номера:", uz:"Xonaning umumiy narxi:"}} /></span>
            <p className='text-[28px] font-[600] text-[#3E3C38]'>{roomtype.price_value} <TranslateText dictionary={{en:"soum", ru:"сум", uz:"so'm "}}/> ({roomtype.price}$)</p>
        </div>
        </div>
        <button className='lg:hidden block mt-10 uppercase text-[21px] md:w-[300px] lg:w-[202px] w-full md:h-[51px] h-[40px] border-2 border-solid border-[#EE9B00] bg-[#EE9B00] hover:bg-[white] text-[white] hover:text-[#EE9B00] rounded-lg'><TranslateText dictionary={{en:"book", ru:"забронировать", uz:"band qilish"}} /></button>
    </form>
  )
}

export default Payment