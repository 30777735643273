import React from 'react'
import TranslateText from '../../context/TranslateText';

const PagesHeader = ({data}) => {
    const bgConfig = {
        background: `url(${data.img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
  return (
    <div style={bgConfig} className='relative block mt-[60px]'>
        <div className='w-full md:min-h-[781px] h-[320px]'></div>
        <div className='text-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
            <h1 className='uppercase text-[#fff] font-[400] lg:text-[67px] md:text-[50px] text-[38px]'><TranslateText dictionary={data.title}/></h1>
            <p className='lg:w-[782px] md:max-w-[782px] w-[250px]   text-[#fff] lg:text-[38px] md:text-[30px] text-[20px] font-[400] mt-3'><TranslateText dictionary={data.description}/></p>
        </div>
    </div>
  )
}

export default PagesHeader