"use client"
import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa'
import CustomSelect from '../CustomSelect'
import { Link } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import TranslateText from '../../context/TranslateText'
const ResNav = () => {
  const NavData= [
    {
      id:1,
      title:{en:"Home",ru:"Главный",uz:"Bosh sahifa"},
      slug:"/"
    },
    {
      id:2,
      title:{en:"about Us", ru:"о нас", uz:"biz haqimizda"},
      slug:"/about"
    },
    {
      id:3,
      title:{en:"rooms", ru:"номера", uz:"Xonalar"},
      slug:"/rooms"
    },
    {
      id:4,
      title:{en:"blog", ru:"блог", uz:"blog"},
      slug:"/news"
    },
    {
      id:5,
      title:{en:"services", ru:"услуги", uz:"xizmatlar"},
      slug:"/service"
    },
    {
      id:6,
      title:{en:"contacts", ru:"контакты", uz:"kontaktlar"},
      slug:"/contact"
    },
    {
      id:7,
      title:{en:"gallery", ru:"галерея", uz:"galereya"},
      slug:"/gallery"
    },
    {
      id:8,
      title:{en:"virtual tour", ru:"виртуальный тур", uz:"virtual sayohat"},
      slug:"/virtual-tour"
    },
    {
      id:9,
      title:{en:"travel packages", ru:"туристические пакеты", uz:"sayohat paketlari"},
      slug:"/tour-packages"
    },
  ]
  const [active,setActive] = useState(false)
  const handleScroll = () => {
     setActive(false)
  };
  
  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);
  return (
    <div className='bg-[#383838] fixed left-0 right-0 top-0 z-20' onScroll={handleScroll}>
        <div className='flex justify-between items-center px-4 text-[white] h-[79px]'>
            <div className='text-[36px]' onClick={()=>setActive(true)}><FaBars/></div>
            <div>
            <div className={`text-[38px] font-montecarlo font-[400] text-[white]`}>
                <Link to={"/"}>&nbsp;&nbsp;&nbsp;&nbsp;Kamilla</Link>
            </div>
            </div>
            <div><CustomSelect/></div>
        </div>
        <div className={`absolute top-0 left-0 right-0 bottom-0 w-full h-screen z-[1000] bg-[#383838] ${active ? "" : "hidden"}`}>
          <div className='text-[38px] text-[white] p-[20px]' onClick={()=>setActive(false)}><AiOutlineClose/></div>
          <br />
          {NavData.map((item, idx)=>(
          <div key={idx} className='p-[15px] px-[20px] text-[16px] font-[600] uppercase font-manrope text-[#FFF] cursor-pointer hover:text-[#EE9B00] transition-[1s]' onClick={()=>setActive(false)}><Link to={item.slug}><TranslateText dictionary={item.title} /></Link></div>
          ))}
        </div>
        <div>
        </div>
    </div>
  )
}

export default ResNav