import React from "react";

//assets
import TourPack_1 from "../../assets/TourPack/Tourpack_1.png";
import TourPack_2 from "../../assets/TourPack/Tourpack_2.png";
import TourPack_3 from "../../assets/TourPack/Tourpack_3.png";
import TourPack_4 from "../../assets/TourPack/Tourpack_4.png";
import TourPack_5 from "../../assets/TourPack/Tourpack_5.png";
import TourPack_6 from "../../assets/TourPack/Tourpack_6.png";
import TranslateText from "../../context/TranslateText";
import { useNavigate } from "react-router-dom";
const TourPack = () => {
  const navigate = useNavigate()
  return (
    <div className="container md:mt-[100px] mt-[50px] md:px-0 px-2">
      <div className="uppercase md:text-[51px] text-[28px] font-normal font-fira-sans mb-8 text-[#220901]">
        <TranslateText
          dictionary={{
            en: "our travel packages",
            ru: "наши туристические пакеты",
            uz: "bizning sayohat paketlarimiz"
          }}
        />
      </div>
      <div className="grid grid-row-2 md:grid-cols-4 grid-cols-2 justify-center items-center">
        <div className="row-span-2 cursor-pointer ">
          <div className="relative" onClick={()=>navigate('/tour-packages')}>
            <img
              src={TourPack_1}
              alt="TourPack_1"
              className="hover:opacity-90 hover:scale-[1.01]  transition-[1s]"
            />
            <p className="capitalize absolute bottom-6 left-6 lg:text-[18px] xl:text-[24px] text-[16px] font-[500] font-manrope text-[white]">
              <TranslateText
                dictionary={{
                  en: "Tudakul lake",
                  ru: "озеро Тудакуль",
                  uz: "Toʻdakoʻl koʻli",
                }}
              />
            </p>
          </div>
          {/* <p className="text-[#7A7A7A] md:text-[16px] text-[12px] font-manrope">
            <span className="text-[#EE9B00] md:text-[28px] text-[16px] font-[600]">
              $150
            </span>{" "}
            <TranslateText
              dictionary={{
                en: "per person",
                ru: "на человека",
                uz: "kishi boshiga",
              }}
            />
          </p> */}
        </div>
        <div className="relative cursor-pointer md:hidden block">
          <div className="relative" onClick={()=>navigate('/tour-packages')}>
            <img
              src={TourPack_4}
              alt="Rest_5"
              className="hover:opacity-90 hover:scale-[1.01]  transition-[1s] w-full"
            />
            <p className="capitalize absolute bottom-6 left-6 lg:text-[18px] xl:text-[24px] text-[16px] font-[500] font-manrope text-[white]">
              <TranslateText
                dictionary={{
                  en: "The Chashma сomplex",
                  ru: "Комплекс Чашма",
                  uz: "Chashma majmuasi",
                }}
              />
             
            </p>
          </div>
          {/* <p className="text-[#7A7A7A] md:text-[16px] text-[12px] font-manrope">
            <span className="text-[#EE9B00] md:text-[28px] text-[16px] font-[600]">
              $150
            </span>{" "}
            <TranslateText
              dictionary={{
                en: "per person",
                ru: "на человека",
                uz: "kishi boshiga",
              }}
            />
          </p> */}
        </div>
        <div className="relative cursor-pointer md:hidden block">
          <div className="relative" onClick={()=>navigate('/tour-packages')}>
            <img
              src={TourPack_3}
              alt="Rest_3"
              className="hover:opacity-90 hover:scale-[1.01]  transition-[1s] w-full"
            />
            <p className="capitalize absolute bottom-6 left-6 lg:text-[18px] xl:text-[24px] text-[16px] font-[500] font-manrope text-[white]">
            <TranslateText
                dictionary={{
                  en: "city tour",
                  ru: "поездка по городу",
                  uz: "shahar bo’ylab sayohati",
                }}
              />
             
            </p>
          </div>
          {/* <p className="text-[#7A7A7A] md:text-[16px] text-[12px] font-manrope">
            <span className="text-[#EE9B00] md:text-[28px] text-[16px] font-[600]">
              $150
            </span>{" "}
            <TranslateText
              dictionary={{
                en: "per person",
                ru: "на человека",
                uz: "kishi boshiga",
              }}
            />
          </p> */}
        </div>
        <div className="col-span-2 relative cursor-pointer">
          <div className="relative" onClick={()=>navigate('/tour-packages')}>
            <img
              src={TourPack_2}
              alt="Rest_2"
              className="hover:opacity-90 hover:scale-[1.01]  transition-[1s] w-full"
            />
            <p className="capitalize absolute bottom-6 left-6 lg:text-[18px] xl:text-[24px] text-[16px] font-[500] font-manrope text-[white]">
              <TranslateText
                dictionary={{
                  en: "the wonders of Sarmishsoy gorge nature",
                  ru: "чудеса природы ущелья Сармишсой",
                  uz: "Sarmishsoy darasi tabiatining ajoyibotlari",
                }}
              />
            </p>
          </div>
          {/* <p className="text-[#7A7A7A] md:text-[16px] text-[12px] font-manrope">
            <span className="text-[#EE9B00] md:text-[28px] text-[16px] font-[600]">
              $150
            </span>{" "}
            <TranslateText
              dictionary={{
                en: "per person",
                ru: "на человека",
                uz: "kishi boshiga",
              }}
            />
          </p> */}
        </div>
        <div className="relative cursor-pointer- md:block hidden">
          <div className="relative" onClick={()=>navigate('/tour-packages')}>
            <img
              src={TourPack_4}
              alt="Rest_5"
              className="hover:opacity-90 hover:scale-[1.01]  transition-[1s] w-full"
            />
            <p className="capitalize absolute bottom-6 left-6 lg:text-[18px] xl:text-[24px] text-[16px] font-[500] font-manrope text-[white]">
            <TranslateText
                dictionary={{
                  en: "The Chashma сomplex",
                  ru: "Комплекс Чашма",
                  uz: "Chashma majmuasi",
                }}
              />
              <br />{" "}
              <TranslateText
                dictionary={{ en: "region", ru: "регион", uz: "tumani" }}
              />
            </p>
          </div>
          {/* <p className="text-[#7A7A7A] md:text-[16px] text-[12px] font-manrope">
            <span className="text-[#EE9B00] md:text-[28px] text-[16px] font-[600]">
              $150
            </span>{" "}
            <TranslateText
              dictionary={{
                en: "per person",
                ru: "на человека",
                uz: "kishi boshiga",
              }}
            />
          </p> */}
        </div>
        <div className="relative cursor-pointer md:block hidden">
          <div className="relative" onClick={()=>navigate('/tour-packages')}>
            <img
              src={TourPack_3}
              alt="Rest_3"
              className="hover:opacity-90 hover:scale-[1.01]  transition-[1s] w-full"
            />
            <p className="capitalize absolute bottom-6 left-6 lg:text-[18px] xl:text-[24px] text-[16px] font-[500] font-manrope text-[white]">
              {" "}
              <TranslateText
                dictionary={{
                  en: "city tour",
                  ru: "поездка по городу",
                  uz: "shahar bo’ylab sayohati",
                }}
              />
          
              
            </p>
          </div>
          {/* <p className="text-[#7A7A7A] md:text-[16px] text-[12px] font-manrope">
            <span className="text-[#EE9B00] md:text-[28px] text-[16px] font-[600]">
              $150
            </span>{" "}
            <TranslateText
              dictionary={{
                en: "per person",
                ru: "на человека",
                uz: "kishi boshiga",
              }}
            />
          </p> */}
        </div>

        <div className="relative cursor-pointer">
          <div className="relative" onClick={()=>navigate('/tour-packages')}>
            <img
              src={TourPack_5}
              alt="Rest_6"
              className="hover:opacity-90 hover:scale-[1.01]  transition-[1s] w-full"
            />
            <p className="capitalize absolute bottom-6 left-6 lg:text-[18px] xl:text-[24px] text-[16px] font-[500] font-manrope text-[white]">
              <TranslateText
                dictionary={{
                  en: "Aydarkul –a pristine beauty oasis",
                  ru: "Айдаркуль – оазис первозданной красоты",
                  uz: "Aydarko'l - go'zallik vohasi",
                }}
              />
              
            </p>
          </div>
          {/* <p className="text-[#7A7A7A] md:text-[16px] text-[12px] font-manrope">
            <span className="text-[#EE9B00] md:text-[28px] text-[16px] font-[600]">
              $150
            </span>{" "}
            <TranslateText
              dictionary={{
                en: "per person",
                ru: "на человека",
                uz: "kishi boshiga",
              }}
            />
          </p> */}
        </div>
        <div className="relative cursor-pointer">
          <div className="relative" onClick={()=>navigate('/tour-packages')}>
            <img
              src={TourPack_6}
              alt="Rest_4"
              className="hover:opacity-90 hover:scale-[1.01]  transition-[1s] w-full"
            />
            <p className="capitalize absolute bottom-6 left-6 lg:text-[18px] xl:text-[24px] text-[16px] font-[500] font-manrope text-[white]">
              <TranslateText
                dictionary={{
                  en: "Wonderful Sentob village",
                  ru: "Замечательное село Сентоб",
                  uz: "Ajoyib Sentob qishlog'i",
                }}
              />
              
            </p>
          </div>
          {/* <p className="text-[#7A7A7A] md:text-[16px] text-[12px] font-manrope">
            <span className="text-[#EE9B00] md:text-[28px] text-[16px] font-[600]">
              $150
            </span>
            <TranslateText
              dictionary={{
                en: "per person",
                ru: "на человека",
                uz: "kishi boshiga",
              }}
            />
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default TourPack;
