import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roomtype: {
    room:"",
    price_value:0,
    price:0
  },
};

export const roomTypeSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    setRoomtype: (state, { payload }) => {
      state.roomtype = payload;
    },
  },
});

export const { setRoomtype } = roomTypeSlice.actions;

export default roomTypeSlice.reducer;
