import React, { useEffect, useRef } from 'react'
import MainImg from "../../assets/rooms/Nomer_2 (2).png"
import MainImg2 from "../../assets/rooms/mainroom.png"
import Nomer_2 from "../../assets/rooms/Nomer_2_1.png"
import Icon_1 from "../../assets/rooms/Icon_1.png"
import Icon_2 from "../../assets/rooms/Icon_2.png"
import Icon_3 from "../../assets/rooms/Icon_3.png"
import Icon_4 from "../../assets/rooms/Icon_4.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import { setModal } from '../../redux/modal.slice'
import Virtual from "../../assets/images/viirtual.png"
import {rooms} from "../../data/rooms"
import {GrNext,GrPrevious} from "react-icons/gr"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


// import required modules
import {Pagination, Navigation } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom'
import Modal from '../../components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import TranslateText from '../../context/TranslateText'
import Seohelmet from '../../components/Helmet'
export const AboutRoom = () =>{
  return(
    <div className='container grid md:grid-cols-2 grid-cols-1 gap-10 h-[960px] mb-5 '>
      <div className='mt-20'>
        <h2 className='text-[#220901] text-[51px] font-[400] font-fira-sans uppercase'><TranslateText dictionary={{en:"ROOM", ru:"КОМНАТА", uz:"XONA"}} />  <br /> <TranslateText dictionary={{en:"DETAILS", ru:"ПОДРОБНОСТИ", uz:"TAFSILOTI"}} /></h2>
        <p className='text-[21px] font-[400]8 mt-10 font-manrope text-[#3E3C38]'><TranslateText dictionary={{
          en:"Our hotel offers spacious and tastefully decorated rooms and suites, offering a relaxing atmosphere where you can unwind after a long day of research or business activities. Each room is equipped with modern amenities to ensure your comfort and convenience throughout your stay.",
          ru:"Наш отель предлагает просторные и со вкусом оформленные номера и люксы, предлагающие расслабляющую атмосферу, где вы можете расслабиться после долгого дня, проведенного в исследованиях или деловой деятельности. Каждый номер оснащен современными удобствами, обеспечивающими ваш комфорт и удобство на протяжении всего вашего пребывания.",
          uz:"Mehmonxonamiz keng va did bilan bezatilgan xonalar va lyukslarni taklif etadi, ular uzoq kunlik tadqiqot yoki biznes faoliyatidan keyin dam olishingiz mumkin bo'lgan tasalli muhitni taklif etadi. Har bir xona sizning butun yashashingiz davomida qulaylik va qulaylikni ta'minlash uchun zamonaviy jihozlar bilan jihozlangan."
        }} /></p>
       <button id='btn' className='m-auto mt-[30px] md:mt-[50px]  uppercase 2xl:text-[21px] xl:text-[18px] 2xl:w-[267px] xl:w-[176px] lg:w-[176px] w-[152px] 2xl:h-[51px] xl:h-[45px] h-[37px] border-2 border-solid border-[#EE9B00] hover:bg-[#EE9B00] hover:text-[white] hover:transition-[1s] transition-[1s] text-[#EE9B00] rounded-lg'><Link to={"/bron"}><TranslateText dictionary={{en:"Book", ru:"бронировать", uz:"band qilish"}} /></Link></button>

      </div>
      <div className='relative bg-[#F0F0F0] '>
      <div className='grid grid-cols-1 w-[531px] absolute top-[45%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <div className='row-span-5'>
        <img src={Nomer_2} alt="Nomer_2"  />
        </div>
        <div className='grid grid-cols-2 bg-[#F0F0F0] px-2'>
          <div>
            <h1 className='capitalize text-[21px] font-fira-sans font-[400] my-6'><TranslateText dictionary={{en:"room", ru:"номера", uz:"xona"}} /></h1>
            <ul className='text-[16px] font-manrope font-[500]'>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"7 single rooms", ru:"7 одноместных номеров", uz:"7 ta bir kishilik xona"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"26 double rooms", ru:"26 двухместных номеров", uz:"26 ta ikki kishilik xona"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"3 triple rooms", ru:"3 трехместных номера", uz:"3 ta uch kishilik xona"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"11 junior suites", ru:"11 номеров полулюкс", uz:"11 ta junior suit"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"1 suite", ru:"1 номер люкс", uz:"1 suit"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"2 deluxe rooms", ru:"2 номера суперлюкс", uz:"2 ta lyuks xona"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"2 apartments", ru:"2 номера-апартаменты", uz:"2 ta kvartira"}} /></li>
            </ul>
          </div>
          <div>
            <h1 className='capitalize text-[21px] font-fira-sans font-[400] my-6'><TranslateText dictionary={{en:"Booking conditions", ru:"Условия бронирования", uz:"Band qilish shartlari"}} /></h1>
            <div className='flex justify-between mb-3'>
              <p className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{en:"Check-in time:", ru:"Время заезда:", uz:"Ro'yxatdan o'tish vaqti:"}} /></p>
              <p>14:00</p>
            </div>
            <div className='flex justify-between mb-3'>
              <p className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{en:"Check-in time:", ru:"Время заезда:", uz:"Ro'yxatdan o'tish vaqti:"}} /></p>
              <p>12:00</p>
            </div>
            <div className='mb-3'>
              <p className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{
                en:"Children accommodation:", ru:"Размещение детей:", uz:"Bolalar turar joyi:"
              }} /></p>
              <p><TranslateText dictionary={{en:"Children under 6 years old stay free on existing beds", ru:"Размещение детей до 6 лет на имеющихся кроватях бесплатно", uz:"6 yoshgacha bo'lgan bolalar mavjud yotoqlarda bepul qolishadi"}} /></p>
            </div>
            <div className='mb-3'>
              <p className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{en:"Pets:", ru:"Размещение животных:", uz:"Uy hayvonlari:"}} /></p>
              <p><TranslateText dictionary={{en:"Pets are not allowed", ru:"Размещение домашних животных не допускается", uz:"Uy hayvonlariga ruxsat berilmaydi"}} /></p>
            </div>
          </div>
        </div>
        {/* <div className='mt-5 w-full'>
          <h2 className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{en:"Marriage rule for citizens of Uzbekistan:", ru:"Правило о браке для граждан Узбекистана:", uz:"O‘zbekiston fuqarolari uchun nikoh qoidalari:"}} /></h2>
          <p className='text-[12px] font-manrope font-[500] text-[#3E3C38]'><TranslateText dictionary={{
            en:"Unmarried couples are not allowed to stay at the hotel. Couples are required to present a marriage certificate upon check-in",
            ru:"Пары, не состоящие в браке, не допускаются к проживанию в гостинице. При регистрации заезда пара в обязательном порядке должна предъявить свидетельство о браке",
            uz:"Turmush qurmagan er-xotinlar mehmonxonada qolishlari mumkin emas. Ro'yxatdan o'tish paytida er-xotinlar nikoh guvohnomasini taqdim etishlari kerak"
          }} /></p>
        </div> */}
      </div>
      </div>
    </div>
  )
}
export const AboutRoomrRes = ()=>{
  return(
    <div className=''>
        <div className='px-[10px]'>
          <h2 className='text-[28px] my-[20px] font-[400] text-[#220901] font-fira-sans'><TranslateText dictionary={{en:"ROOM", ru:"КОМНАТА", uz:"XONA"}} />  <br /> <TranslateText dictionary={{en:"DETAILS", ru:"ПОДРОБНОСТИ", uz:"TAFSILOTI"}} /></h2>
          <p className='text-[16px] font-manrope text-[#3E3C38]'>
            <TranslateText dictionary={{
              en:"Our hotel offers spacious and tastefully decorated rooms and suites, offering a relaxing atmosphere where you can unwind after a long day of research or business activities. Each room is equipped with modern amenities to ensure your comfort and convenience throughout your stay.",
              ru:"Наш отель предлагает просторные и со вкусом оформленные номера и люксы, предлагающие расслабляющую атмосферу, где вы можете расслабиться после долгого дня, проведенного в исследованиях или деловой деятельности. Каждый номер оснащен современными удобствами, обеспечивающими ваш комфорт и удобство на протяжении всего вашего пребывания.",
              uz:"Mehmonxonamiz keng va did bilan bezatilgan xonalar va lyukslarni taklif etadi, ular uzoq kunlik tadqiqot yoki biznes faoliyatidan keyin dam olishingiz mumkin bo'lgan tasalli muhitni taklif etadi. Har bir xona sizning butun yashashingiz davomida qulaylik va qulaylikni ta'minlash uchun zamonaviy jihozlar bilan jihozlangan."
            }} />
            </p>
          <button id='btn' className='m-auto mb-[50px] mt-[30px] md:mt-[50px]  uppercase 2xl:text-[21px] xl:text-[18px] 2xl:w-[267px] xl:w-[176px] lg:w-[176px] w-[152px] 2xl:h-[51px] xl:h-[45px] h-[37px] border-2 border-solid border-[#EE9B00] hover:bg-[#EE9B00] hover:text-[white] hover:transition-[1s] transition-[1s] text-[#EE9B00] rounded-lg'><Link to={"/bron"}><TranslateText dictionary={{en:"booking", ru:"бронировать", uz:"band qilish"}} /></Link></button>

        </div>
        <div className='relative px-[40px] pb-[40px]'>
          <div className='flex justify-center items-center '><img src={Nomer_2} alt="Nomer_2" className=' rounded-[10px]' /></div>
          <div>
            <h1 className='capitalize text-[21px] font-fira-sans font-[400] my-6'>номера</h1>
            <ul className='text-[16px] font-manrope font-[500]'>
            <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"7 single rooms", ru:"7 одноместных номеров", uz:"7 ta bir kishilik xona"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"26 double rooms", ru:"26 двухместных номеров", uz:"26 ta ikki kishilik xona"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"3 triple rooms", ru:"3 трехместных номера", uz:"3 ta uch kishilik xona"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"11 junior suites", ru:"11 номеров полулюкс", uz:"11 ta junior suit"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"1 suite", ru:"1 номер люкс", uz:"1 suit"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"2 deluxe rooms", ru:"2 номера суперлюкс", uz:"2 ta lyuks xona"}} /></li>
              <li className='mb-3 font-manrope text-[16px] font-[500] text-[#3E3C38]'><span className='text-[#EE9B00] pr-3'>●</span> <TranslateText dictionary={{en:"2 apartments", ru:"2 номера-апартаменты", uz:"2 ta kvartira"}} /></li>
            </ul>
          </div>
          <div>
            <h1 className='capitalize text-[21px] font-fira-sans font-[400] my-6'><TranslateText dictionary={{en:"Booking conditions", ru:"Условия бронирования", uz:"Band qilish shartlari"}} /></h1>
            <div className='flex justify-between mb-3'>
              <p className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{en:"Check-in time:", ru:"Время заезда:", uz:"Ro'yxatdan o'tish vaqti:"}} /></p>
              <p>14:00</p>
            </div>
            <div className='flex justify-between mb-3'>
              <p className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{en:"Check-in time:", ru:"Время заезда:", uz:"Ro'yxatdan o'tish vaqti:"}} /></p>
              <p>12:00</p>
            </div>
            <div className='mb-3'>
              <p className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{
                en:"Children accommodation:", ru:"Размещение детей:", uz:"Bolalar turar joyi:"
              }} /></p>
              <p><TranslateText dictionary={{en:"Children under 6 years old stay free on existing beds", ru:"Размещение детей до 6 лет на имеющихся кроватях бесплатно", uz:"6 yoshgacha bo'lgan bolalar mavjud yotoqlarda bepul qolishadi"}} /></p>
            </div>
            <div className='mb-3'>
              <p className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{en:"Pets:", ru:"Размещение животных:", uz:"Uy hayvonlari:"}} /></p>
              <p><TranslateText dictionary={{en:"Pets are not allowed", ru:"Размещение домашних животных не допускается", uz:"Uy hayvonlariga ruxsat berilmaydi"}} /></p>
            </div>
          </div>
        </div>
        {/* <div className='mt-5 w-full'>
          <h2 className='text-[#EE9B00] text-[16px] font-manrope font-[500]'><TranslateText dictionary={{en:"Marriage rule for citizens of Uzbekistan:", ru:"Правило о браке для граждан Узбекистана:", uz:"O‘zbekiston fuqarolari uchun nikoh qoidalari:"}} /></h2>
          <p className='text-[12px] font-manrope font-[500] text-[#3E3C38]'><TranslateText dictionary={{
            en:"Unmarried couples are not allowed to stay at the hotel. Couples are required to present a marriage certificate upon check-in",
            ru:"Пары, не состоящие в браке, не допускаются к проживанию в гостинице. При регистрации заезда пара в обязательном порядке должна предъявить свидетельство о браке",
            uz:"Turmush qurmagan er-xotinlar mehmonxonada qolishlari mumkin emas. Ro'yxatdan o'tish paytida er-xotinlar nikoh guvohnomasini taqdim etishlari kerak"
          }} /></p>
        </div> */}
    </div>
  )
}
export const RoomType = () =>{
  const { modal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  return(
    <div>
      {modal.open? <Modal/> : "" }
    <div className='xl:flex block h-full xl:max-h-[750px]'>
      
      <div className='xl:block  xl:w-[40%] w-full 2xl:w-[50%] relative cursor-pointer'>
        <img src={Virtual} alt="Virtual" className='hover:scale-[1.5] transition-all absolute translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%]' onClick={()=>dispatch(setModal({open:true,link:rooms[0].link}))}/>
        <img src={rooms[0].img} alt="" className='w-full h-full max-h-[600px]'/>
      </div>
      <div className='xl:w-[60%] w-full bg-[#383838] lg:p-10 p-3'>
        <h1 className='uppercase font-[400] 2xl:text-[51px] xl:text-[38px] text-[28px] font-fira-sans text-[white]'><TranslateText dictionary={rooms[0].title} /></h1>
        <div className='flex gap-5 font-manrope font-[600] text-[white]'>
        <p className='lg:text-[28px] lg:14px'><TranslateText dictionary={{en:"from", ru:"от", uz:""}} /></p>
          <p className='2xl:text-[67px] xl:text-[51px] md:text-[32px] text-[25px]'>{rooms[0].price_valuta} <TranslateText dictionary={{en:"soum", ru:"сум", uz:"so'm "}}/> ({rooms[0].price}$) <TranslateText dictionary={{en:"", ru:"", uz:"dan"}} /></p>

        </div>
        <div className='grid lg:grid-cols-2 grid-cols-1  lg:gap-20 gap-10'>
          <div className='text-[#C5C5C5] mt-5'>
          <TranslateText dictionary={rooms[0].description} />
          <br /><br />
          <button className='lg:block hidden text-[21px] mt-10 font-[600] text-center uppercase text-[white] border-2 border-solid border-[#fff] hover:bg-[white] hover:text-[#383838] px-10 py-2 rounded'><Link to={"/payment"}><TranslateText dictionary={{en:"book now", ru:"Забронируйте сейчас", uz:"Hoziroq band qiling"}} /></Link></button>
          </div>
          <div>
            <div className='flex gap-5 mb-2'><img src={Icon_1} alt="Icon_1" /> <p className='mt-5 lg:text-[21px] text-[12px] text-[white]'><TranslateText dictionary={{en:"Double bed", ru:"Двойная кровать", uz:"Ikki kishilik karavot"}} /></p></div>
            <div className='flex gap-3 mb-2'><div className='flex justify-start items-end'>
            <img src={Icon_2} alt="Icon_2" className='flex-shrink'/>
            <img src={Icon_2} alt="Icon_2" className=' h-[60%] flex-shrink ml-[-15px]' /></div> <p className='mt-5 ml-[-15px] lg:text-[21px] text-[12px] text-[white]'><TranslateText dictionary={{en:"2 adults 1 child", ru:"2 взрослых 1 ребенок", uz:"2 kattalar 1 bola"}} /></p></div>
            <div className='flex gap-5 mb-2'><img src={Icon_3} alt="Icon_3" /> <p className='mt-5 lg:text-[21px] text-[12px] text-[white]'>30 m<sup>2</sup></p></div>
            <div className='flex gap-5 mb-2'><img src={Icon_4} alt="Icon_4" /> <p className='mt-5 lg:text-[21px] text-[12px] text-[white]'><TranslateText dictionary={{en:"Bath", ru:"Ванна", uz:"Vanna"}} /></p></div>
          </div>
          <button className='lg:hidden block text-[21px] lg:mt-10 mt-1 font-[600] text-center uppercase text-[white] border-2 border-solid border-[#fff] hover:bg-[white] hover:text-[#383838] px-10 py-2 rounded-[10px]'><Link to={"/payment"} ><TranslateText dictionary={{en:"book now", ru:"Забронируйте сейчас", uz:"Hoziroq band qiling"}} /></Link></button>
        </div>
      </div>
    </div>
    </div>
  )
}
export const ImageGallery = ({Imglist = rooms[0].img_list}) =>{
  return(
    <div className='ImageGal mt-5 mb-20'>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        navigation
        breakpoints={{
          340: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        loop
        modules={[Pagination,Navigation]}
        className="mySwiper"
      >
        {Imglist.map(item=>(
        <SwiperSlide>
          <div className='md:w-[468px] md:h-[467px] h-[273px] mx-2'>
          <img src={item.url} alt={item.id}  className='2xl:w-[346px] 2xl:h-[477px] xl:w-[305px] xl:h-[477px] w-[154px] h-[241px] '/>
          </div>
        </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
export const AnotherRoooms = ({data=rooms}) =>{
  const swiperRef = useRef()
  const navigate = useNavigate()
  // const data = [
  //   {
  //     id:1,
  //     title:"Одноместный стандарт",
  //     description:"Это уютная и функциональная комната, предназначенная для одного гостя. Этот тип номера идеально подходит для индивидуальных путешественников, которым требуется комфортное и экономичное проживание.",
  //     slug:"/"
  //   },
  //   {
  //     id:2,
  //     title:"Одноместный полу - люкс",
  //     description:"Это роскошный и просторный номер, предназначенный для одного гостя, который ценит комфорт и дополнительные удобства. Этот тип номера обеспечивает гостю максимальную приватность и роскошный опыт проживания.",
  //     slug:"/"
  //   },
  //   {
  //     id:3,
  //     title:"Двухместный стандарт",
  //     description:"Это уютная и функциональная комната, предназначенная для одного гостя. Этот тип номера идеально подходит для индивидуальных путешественников, которым требуется комфортное и экономичное проживание.",
  //     slug:"/"
  //   },
  // ]
  return(
    <div className='container mb-20 anotherroom'>
      <Seohelmet title={"Kamilla hotel - Rooms"}/>
        <div className='mb-12'>
          <h1 className='font-fira-sans md:text-[51px] text-[28px] font-[400] uppercase text-[#220901]'><TranslateText dictionary={{en:"Other rooms", ru:"Другие комнаты", uz:"Boshqa xonalar"}} /></h1>
          <p className='capitalize md:text-[28px] text-[16px] font-manrope text-[#3E3C38]'><TranslateText dictionary={{en:"YOU MAY ALSO BE INTERESTED", ru:"ТАКЖЕ МОЖЕТ ЗАИНТЕРЕСОВАТЬ ВАС", uz:"SIZGA HAM QIZIQISH MUMKIN"}} /></p>
        </div>
        <div className='flex justify-between items-center'>
          <div className='anotherroom-prev cursor-pointer' onClick={() => swiperRef.current?.slidePrev()}><GrPrevious/></div>
        <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          type: "progressbar",
      }}
        // navigation={true}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 20,
          
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
           
          },
          768: {
            slidesPerView:2,
            spaceBetween: 20,
           
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
           
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 20,
           
          },
        }}
       
     
        modules={[Pagination,Navigation]}
        className="mySwiper"
      >
        {data.map(item=>(
          <SwiperSlide onClick={()=>navigate(`/room/${item.id}`)}>
            <div className='anotherroom-in'>
          <img src={item.img} alt="Nomer_9"  />
          <br />
          <div className='px-1'>
          <h1 className='capitalize text-left text-[28px] font-[400] text-[#383838] font-fira-sans '><TranslateText dictionary={item.title} /></h1>
          <br />
          <hr />
          <br />
          <p className='text-left' id='room-desc'><TranslateText dictionary={item.description} /></p>
          <br />
          <div className='flex justify-between items-center'>
            <p className='text-[16px] font-[600] text-[#000] font-manrope border-b-2 border-solid border-[#000]'><Link to={`/room/${item.id}`}><TranslateText dictionary={{en:"View details", ru:"Посмотреть детали", uz:"Tafsilotlarni ko'rish"}} /></Link></p>
            <p className='text-[28px] font-[600] text-[#3E3C38] '><TranslateText dictionary={{en:"from", ru:"от", uz:""}} /> <span className='text-[#EE9B00]'>{item.price_valuta} <TranslateText dictionary={{en:"soum", ru:"сум", uz:"so'm "}}/> <span className='text-[20px]'>({item.price}$)</span></span><span className='text-[22px]'><TranslateText dictionary={{en:"", ru:"", uz:" dan"}} /></span></p>
          </div>
          </div>
          </div>
        </SwiperSlide>
        ))}
       
      </Swiper>
      <div className='anotherroom-next cursor-pointer' onClick={() => swiperRef.current?.slideNext()}><GrNext/></div>
        </div>
        
    </div>
  )
}
const Rooms = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div>
      <div className='w-full relative md:h-[auto] mt-[50px] md:mt-[0px]'>
        <img src={MainImg} alt="MainImg"  className='md:block hidden w-full'/>
        <img src={MainImg2} alt="MainImg" className='md:hidden block w-full'/>
        <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center text-[#fff] font-fira-sans'>
          <h2 className='xl:text-[67px] md:text-[50px] text-[28px] font-[400] uppercase'><TranslateText dictionary={{en:"Ours rooms", ru:"Наши номера", uz:"Bizning xonalar"}} /></h2>
          <p className='xl:text-[38px] md:text-[30px] text-[16px] font-[400] uppercase'><TranslateText dictionary={{en:"DISCOVER LUXURY HOTEL LIFE", ru:"ОТКРОЙТЕ ДЛЯ СЕБЯ РОСКОШНУЮ ЖИЗНЬ ОТЕЛЯ", uz:"LUKS HOTEL HAYOTINI KASHF ETING"}} /></p>
        </div>
      </div>
      <div className='lg:block hidden'>
      <AboutRoom/>
      </div>
      <div className='lg:hidden block'>
      <AboutRoomrRes/>
      </div>
      <RoomType/>
      <ImageGallery/>
      <AnotherRoooms/>
    </div>
  )
}

export default Rooms