import React from 'react'
import splash from "../../assets/loader/splash.png"
import splashscreen from "../../assets/loader/splashscreen.png"
import mobile from "../../assets/loader/mobile.png"
const Loader = () => {
  return (
    <div className='relative w-full h-full'>
      <img src={splash} alt="splash hidden 2xl:block" />
      <img src={splashscreen} alt="splsplashscreenash" className='hidden lg:block 2xl:hidden' />
      <img src={mobile} alt="mobile" className='hidden sm:block lg:hidden'/>
    </div>
  )
}

export default Loader