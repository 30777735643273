import React from 'react'

//icons
import {AiOutlineTwitter} from "react-icons/ai"
import {FaFacebookF,FaTelegramPlane,FaInstagram} from "react-icons/fa"

// Link for Routes
import { Link } from 'react-router-dom'
import TranslateText from '../../context/TranslateText'

const Index = () => {
  return (
    <div className='bg-main-color w-full  text-[#FFF] py-5 mt-[100px] px-[10px]'>
        <div className='container'>
            <div className='w-full text-center text-[#FFF] font-[400] font-montecarlo not-italic sm:text-[63px] text-[38px]'>
                <p>Kamilla</p>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-4 sm:justify-center gap-8 text-left sm:text-left  w-full font-manrope sm:py-12 py-6 '>
                <div>
                    <h1 className='uppercase font-fira-sans sm:text-[28px] text-[23px]'><TranslateText dictionary={{en:"OUR ADDRESS", ru:"НАШ АДРЕС", uz:"Bizning manzil"}} /></h1>
                    <p className='my-5 sm:text-[16px] text-[14px]'><TranslateText dictionary={{en:"Republic of Uzbekistan, 210100,", ru:"Республика Узбекистан, 210100,", uz:"O‘zbekiston Respublikasi, 210100,"}} /> <br /> <TranslateText dictionary={{en:"Navoi region, Navoi city,", ru:"Навоийская область, город Навои,", uz:"Navoiy viloyati, Navoiy shahri,"}} /> <br /> <TranslateText dictionary={{en:"Akhunbabaev street 2-2.", ru:"ул.Ахунбабаева 2-2.", uz:"Oxunboboev ko'chasi 2-2."}} /></p>
                </div>
                <div className='sm:grid xl:justify-start sm:justify-end'>
                    <h1 className='uppercase font-fira-sans sm:text-[28px] text-[23px]'><TranslateText dictionary={{en:"reservation", ru:"бронирование", uz:"bron qilish"}} /></h1>
                    <div className='flex gap-2 my-5  justify-start'>
                        <div className='sm:text-[16px] text-[14px]'><TranslateText dictionary={{en:"phone", ru:"тел:", uz:"tel"}} /> </div>
                        <div className='sm:text-[16px] text-[14px]'><span className='text-[#fff] hover:text-[#EE9B00] cursor-pointer'>(+99879) 223-9997,</span> <br />  <span className='text-[#fff] hover:text-[#EE9B00] cursor-pointer'>(+99879) 223-1473,</span> <br /> <span className='text-[#fff] hover:text-[#EE9B00] cursor-pointer'>(+99879) 223-4515,</span></div>
                    </div>
                    <p className='text-[#fff] hover:text-[#EE9B00] cursor-pointer'>info@hotel-kamilla.uz</p>
                </div>
                <div>
                    <h1 className='uppercase font-fira-sans sm:text-[28px] text-[23px]'><TranslateText dictionary={{en:"Newsletter", ru:"Новостная рассылка", uz:"Axborot byulleteni"}} /></h1>
                    <input type="email" placeholder='ваш Email' className='sm:my-5 sm:min-w-[282px] min-w-[230px] my-3 bg-[transparent] border-b-2 border-solid border-[#B7B7B7] py-3 capitalize text-[16px]'/><br /><br />
                    <button className='text-[16px] uppercase px-6 py-2 rounded-md border-2 border-solid border-[#B7B7B7] hover:bg-[#fff] hover:text-[#3a3939] font-[600]'><TranslateText dictionary={{en:"subscribe", ru:"подписаться", uz:"obuna bo'ling"}} /></button>
                </div>
                <div className='flex gap-5 items-end sm:justify-end justify-start'>
                    <Link className='p-3 bg-[#7A7A7A] hover:bg-[#EE9B00]  w-[40px] h-[40px] rounded-full cursor-pointer'><FaInstagram/></Link>
                    <Link className='p-3 bg-[#7A7A7A] hover:bg-[#EE9B00]  w-[40px] h-[40px] rounded-full cursor-pointer'><FaFacebookF/></Link>
                    <Link className='p-3 bg-[#7A7A7A] hover:bg-[#EE9B00]  w-[40px] h-[40px] rounded-full cursor-pointer'><AiOutlineTwitter/></Link>
                    <Link className='p-3 bg-[#7A7A7A] hover:bg-[#EE9B00]  w-[40px] h-[40px] rounded-full cursor-pointer'><FaTelegramPlane/></Link>
                </div>
            </div>
            </div>
            <hr  className='bg-[#B7B7B7]'/>
            <div className='contaner lg:flex justify-between items-center py-5 container text-center'>
            <div className='uppercase lg:hidden block'>
                    <ul className='py-4  hidden sm:flex lg:hidden  justify-center items-center  gap-8 text-[16px] font-[600]'>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link><TranslateText dictionary={{en:"residence", ru:"проживания", uz:"turar joy"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link><TranslateText dictionary={{en:"restaurant", ru:"ресторан", uz:"restoran"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/gallery"}><TranslateText dictionary={{en:"gallery", ru:"галерея", uz:"galereya"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/news"}><TranslateText dictionary={{en:"blog", ru:"блог", uz:"blog"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/about"}><TranslateText dictionary={{en:"about us", ru:"о нас", uz:"biz haqimizda"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/contact"}><TranslateText dictionary={{en:"contacts", ru:"контакты", uz:"kontaktlar"}} /></Link></li>
                    </ul>
                </div> 
                <div className='text-[#848484] text-[14px] sm:text-center text-left'>Copyright © 2023 kamilla hotel</div>
                <div className='uppercase hidden lg:block'>
                    <ul className='hidden lg:flex gap-6 text-[16px] justify-center items-center font-[600]'>
                    <li className='cursor-pointer hover:text-[#EE9B00]'><Link><TranslateText dictionary={{en:"residence", ru:"проживания", uz:"turar joy"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/service"}><TranslateText dictionary={{en:"services", ru:"услуги", uz:"xizmatlar"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/gallery"}><TranslateText dictionary={{en:"gallery", ru:"галерея", uz:"galereya"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/news"}><TranslateText dictionary={{en:"blog", ru:"блог", uz:"blog"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/about"}><TranslateText dictionary={{en:"about us", ru:"о нас", uz:"biz haqimizda"}} /></Link></li>
                        <li className='cursor-pointer hover:text-[#EE9B00]'><Link to={"/contact"}><TranslateText dictionary={{en:"contacts", ru:"контакты", uz:"kontaktlar"}} /></Link></li>
                    </ul>
                </div> 
            </div>
    </div>
  )
}

export default Index