import React, { useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { Link } from 'react-router-dom'


//assets
import MainImg from "../../assets/main/main_2.png"
import AboutImg from "../../assets/about/kabout/kabout_4.png"
import ContactImg from "../../assets/about/Aboutpage_3.png"
import Service from "../../assets/about/About_14.png"
import RoomImg from "../../assets/rooms/Room_1_5.png"
import BlogImg from "../../assets/about/Aboutpage_2.png"
import Gallery_2 from "../../assets/galery/gal/gal_11.png"
import Virt from "../../assets/rooms/Room_1_3.png"
import Tour from "../../assets/TourPack/pack_1.png"
import Rooms_4 from "../../assets/rooms/Rooms_4.png"
import Rooms_5 from "../../assets/rooms/Rooms_5.png"
import Rooms_6 from "../../assets/rooms/Rooms_6.png"
import Rooms_7 from "../../assets/rooms/Rooms_7.png"
import Rooms_8 from "../../assets/rooms/Rooms_8.png"
import { AiOutlineCloseCircle } from 'react-icons/ai'
import CustomSelect from '../CustomSelect'
import TranslateText from '../../context/TranslateText'
const dataNavbar = [
  {
    id:1,
    title:{
      en:"Main",
      ru:"Главный",
      uz:"Bosh sahifa"
    },
    image:MainImg,
    slug:"/"
  },
  {
    id:2,
    title:{
      en:"About",
      ru:"о нас",
      uz:"Biz haqimizda"
    },
    image:AboutImg,
    slug:"/about"
  },
  {
    id:3,
    title:{
      en:"Rooms",
      ru:"номера",
      uz:"Xonalar"
    },
    image:RoomImg,
    slug:"/rooms"
  },
 
  {
    id:4,
    title:{
      en:"Blog",
      ru:"блог",
      uz:"Blog"
    },
    image:BlogImg,
    slug:"/news"
  },
  {
    id:5,
    title:{
      en:"Service",
      ru:"услуги",
      uz:"Xizmatlar"
    },
    image:Service,
    slug:"/service"
  },
  {
    id:6,
    title:{
      en:"Contacts",
      ru:"контакты",
      uz:"Kontaktlar"
    },
    image:ContactImg,
    slug:"/contact"
  },
  {
    id:7,
    title:{
      en:"Gallery",
      ru:"галерея",
      uz:"Galeriya"
    },
    image:Gallery_2,
    slug:"/gallery"
  },
  {
    id:8,
    title:{en:"virtual tour", ru:"виртуальный тур", uz:"virtual sayohat"},
    image:Virt,
    slug:"/virtual-tour"
  },
  {
    id:9,
    title:{en:"travel packages", ru:"туристические пакеты", uz:"sayohat paketlari"},
    image:Tour,
    slug:"/tour-packages"
  }
]
const Rooms =[
  {
    id:9,
    title:{
      en:"Single standard",
      ru:"одноместный стандарт",
      uz:"Bir kishilik standart"
    },
    image:Rooms_4,
    slug:"/rooms"
  },
  {
    id:10,
    title:{
      en:"Single semi - suite",
      ru:"одноместный полу - люкс",
      uz:"Bir kishilik Junior Suite"
    },
    image:Rooms_5,
    slug:"/rooms"
  },
  {
    id:11,
    title:{
      en:"Double standard",
      ru:"двухместный стандарт",
      uz:"Ikki kishilik standart"
    },
    image:Rooms_6,
    slug:"/rooms"
  },
  {
    id:12,
    title:{
      en:"Double semi - deluxe",
      ru:"двухместный полу - люкс",
      uz:"Ikki kishilik Junior Suite"}
    ,
    image:Rooms_7,
    slug:"/rooms"
  },
  {
    id:13,
    title:{
      en:"Suite",
      ru:"люкс",
      uz:"Suite"
    },
    image:Rooms_8,
    slug:"/rooms"
  },
]
const Navbar = () => {
  const [active,setActive] = useState(false)
  const [changeImg,setChangeImg] = useState(MainImg)
  const [line,setLine] = useState(false)
  const handleMouseOver = (item) =>{
    setChangeImg(dataNavbar.find(x=>x.id === item).image)
    setLine(dataNavbar.find(x=>x.id === item).id)
  }
  const handleRoomMouseOver = (item) =>{
    setChangeImg(Rooms.find(x=>x.id === item).image)
    setLine(Rooms.find(x=>x.id === item).id)
  }
  const handleOpen = () =>{
    setActive(prev=>!prev)
    setChangeImg(dataNavbar[0].image)
    setLine(1)
  }
  return (
    <div className={`${active ? "bg-[#383838] transition-all" : "bg-[#383838] transition-all"} fixed left-0 right-0 top-0 z-[11]`} onMouseLeave={()=>setActive(false)} >
      <div className='container flex justify-between items-center relative z-10'>
            <div className={`flex gap-10 text-[16px] uppercase ${active ? 'text-[white]' : "text-[white]"}`}>
                <div>
                  <h3 className='text-[16px]'>адрес</h3>
                  <p className='text-[12px]'>2-2 , ул. Ахунбабаева, 210100, г. Навои</p>
                </div>
                <div>
                  <h3 className='text-[16px]'>тел</h3>
                  <p className='text-[12px]'>(+99879) 223-9997,</p>
                </div>
            </div>
            <div className={`text-[63px] font-montecarlo font-[400] ${active ? 'text-[white]' : "text-[white]"}`}>
                <Link to={"/"}>Kamilla</Link>
            </div>
            <div className={`flex xl:gap-20 lg:gap-10  text-[16px] justify-center items-center uppercase ${active ? 'text-[white]' : "text-[white]"}`}>
                <button className='text-[16px] cursor-pointer  uppercase md:text-[16px]  px-6   md:w-[176px]  h-[44px] border-2 border-solid border-[#EE9B00] hover:bg-[#EE9B00] hover:text-[white] text-[#EE9B00] rounded-lg'><Link to={"/bron"}><TranslateText dictionary={{en:"book now", ru:"бронировать", uz:"band qilish"}}/></Link></button>
                <div className='-mt-[7px]'><CustomSelect fixedNavbar={active}/></div>
                <div onClick={handleOpen}><span className='text-[24px] cursor-pointer'>{active ?  <AiOutlineCloseCircle/> : <FaBars/> }</span></div>
            </div>
            {active ? <div className='absolute mt-[1px] transition-all grid grid-cols-3 gap-10 top-[100%] right-0 bg-[#383838] p-20'>
                <div>
                  {dataNavbar.map(item=>(
                    <>
                  <p className={`'text-[16px] font-[600] text-[white] uppercase  font-manrope inline cursor-pointer ${(line === item.id)? 'border-b-2 border-solid border-[#383838]': ""}`} onMouseOver={()=>handleMouseOver(item.id)}><Link to={item.slug}><TranslateText
          dictionary={item.title}
        /></Link></p>
                  <br /><br />
                  </>
                  ))}
                </div>
                <div><img src={changeImg} alt="MainImg" className='w-[243px] h-[311px]'/></div>
                <div>
                  <h2 className='capitalize text-[16px] font-[600] font-manrope mb-5 text-[white]'>номера</h2>
                  {Rooms.map(item=>(
                  <>
                  <p  className={`text-[14px] text-[white] font-[500] uppercase font-manrope inline mb-4 cursor-pointer ${(line === item.id)? 'border-b-2 border-solid border-[#383838]': ""}`} onMouseOver={()=>handleRoomMouseOver(item.id)}><Link to={item.slug}><TranslateText
          dictionary={item.title}
        /></Link></p><br /><br />
                  </>
                  ))}
                </div>
            </div> : ""}
            
        </div>
    </div>
  )
}

export default Navbar