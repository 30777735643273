import React, { useEffect } from 'react'

//assets
import Gallery_1 from "../../assets/galery/gal/gal_1.png"
import Gallery_2 from "../../assets/galery/gal/gal_2.png"
import Gallery_3 from "../../assets/galery/gal/gal_3.png"
import Gallery_4 from "../../assets/galery/gal/gal_4.png"
import Gallery_5 from "../../assets/galery/gal/gal_5.png"
import Gallery_6 from "../../assets/galery/gal/gal_6.png"
import Gallery_7 from "../../assets/galery/gal/gal_7.png"
import Gallery_8 from "../../assets/galery/gal/gal_8.png"
import Gallery_9 from "../../assets/galery/gal/gal_9.png"
import Gallery_10 from "../../assets/galery/gal/gal_10.png"
import Gallery_11 from "../../assets/galery/gal/gal_11.png"
import Gallery_12 from "../../assets/galery/gal/gal_12.png"
import Gallery_13 from "../../assets/galery/gal/gal_13.png"
import Gallery_14 from "../../assets/galery/gal/gal_14.png"
import Gallery_15 from "../../assets/galery/gal/gal_15.png"
import Gallery_16 from "../../assets/galery/gal/gal_16.png"
import Gallery_17 from "../../assets/galery/gal/gal_17.png"
import Gallery_18 from "../../assets/galery/gal/gal_18.png"
import Gallery_19 from "../../assets/galery/gal/gal_19.png"
import Gallery_20 from "../../assets/galery/gal/gal_20.png"
import Gallery_21 from "../../assets/galery/gal/gal_21.png"
import Gallery_22 from "../../assets/galery/gal/gal_22.png"
import Gallery_23 from "../../assets/galery/gal/gal_23.png"
import Gallery_24 from "../../assets/galery/gal/gal_24.png"
import Gallery_25 from "../../assets/galery/gal/gal_25.png"
import Gallery_26 from "../../assets/galery/gal/gal_26.png"
import Gallery_27 from "../../assets/galery/gal/gal_27.png"
import Gallery_28 from "../../assets/galery/gal/gal_28.png"
import Gallery_29 from "../../assets/galery/gal/gal_29.png"
import Gallery_30 from "../../assets/galery/gal/gal_30.png"
import Gallery_31 from "../../assets/galery/gal/gal_31.png"
import Gallery_32 from "../../assets/galery/gal/gal_32.png"
import Gallery_33 from "../../assets/galery/gal/gal_33.png"
import TranslateText from '../../context/TranslateText'
import Seohelmet from '../../components/Helmet'

//data
const data = [Gallery_1,Gallery_2,Gallery_3,Gallery_4,Gallery_5,Gallery_6,Gallery_7,Gallery_8,Gallery_9,Gallery_10,Gallery_11,Gallery_12,Gallery_13,Gallery_14,Gallery_15,Gallery_16,Gallery_17,Gallery_18,Gallery_19,Gallery_20,Gallery_21,Gallery_22,Gallery_23,Gallery_24,Gallery_25,Gallery_26,Gallery_27,Gallery_28,Gallery_29,Gallery_30,Gallery_31,Gallery_32,Gallery_33]
const Gallery = () => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (

    <div className='container lg:mt-[160px] mt-[100px]'>
      <Seohelmet title={"Kamilla hotel - Gallery"}/>
      <div className='uppercase md:px-[0] px-[10px] text-[#220901] font-fira-sans md:text-[51px] text-[28px] font-[400] md:my-14 my-[45px]'>

      <TranslateText dictionary={{
        en:"Gallery Grid",
        ru:"Сетка галереи",
        uz:"Galereya"
      }} />
      
      </div>
      <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-5 md:px-[0px] px-[10px] gap-[10px] mb-20'>
        {data.map(item=>(
          <div>
            <img src={item} alt={item} className='w-full rounded-[10px] hover:scale-[1.01]  transition-[1s]'/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Gallery