import { Suspense, lazy, useEffect } from "react";
//routes
import { Route, Routes ,useLocation} from "react-router-dom";


//pages
// import Main from "./pages/Main";
import Footer from "./components/Footer"
// import Rooms from "./pages/Rooms"
import Service from "./pages/Service"
import About from "./pages/About"
import Gallery from "./pages/Gallery"
import News from "./pages/News"
import Contact from "./pages/Contact"
import Navbar from "./components/Navbar";
import ResNav from "./components/ResponsiveNavbar";
import VirTour from "./pages/Virtual-tour";
import Bron from "./pages/Bron"
import Payment from "./pages/Payment";
import TourPack from "./components/Tourpagkags";
import Roomtype from "./components/RoomType";
import Package from "./pages/Package";
import Loader from "./components/loader/loader"
import NotFound from "./pages/NotFound";

const Main = lazy(() => import("./pages/Main"))
const Rooms = lazy(() => import("./pages/Rooms"));


function App() {

  const {pathname} = useLocation()
  return (
    <div className="App">
      {(pathname === "/")? " " : <>
      <div className="lg:hidden block"><ResNav/></div>
      <div className="lg:block hidden"><Navbar/></div>
      </>}
      
      <Routes>
        <Route  path="/" element={<Suspense fallback={<Loader />}><Main/></Suspense>} />
        <Route  path="/rooms" element={<Suspense fallback={<Loader />}><Rooms/></Suspense>} />
        <Route  path="/service" element={<Service/>} />
        <Route  path="/about" element={<About/>} />
        <Route  path="/gallery" element={<Gallery/>} />
        <Route  path="/news" element={<News/>} />
        <Route  path="/contact" element={<Contact/>} />
        <Route  path="/virtual-tour" element={<VirTour/>} />
        <Route  path="/tour-packages" element={<Package/>} />
        <Route  path="/bron" element={<Bron/>} />
        <Route  path="/payment" element={<Payment/>} />
        <Route  path="/room/:slug" element={<Roomtype/>} />
        <Route  path="/room/:slug" element={<Roomtype/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
