import { v4 as uuidv4 } from 'uuid';

// double room 
import double1 from "../assets/room/double_standart/Rectangle 120.png"
import double2 from "../assets/room/double_standart/Rectangle 122.png"
import double3 from "../assets/room/double_standart/Rectangle 123.png"
import double4 from "../assets/room/double_standart/Rectangle 124.png"
import double5 from "../assets/room/double_standart/Rectangle 125.png"
import double6 from "../assets/room/double_standart/Rectangle 126.png"

// poli lyuks 
import poli1 from "../assets/room/double_poli_lyuks/Rectangle 120.png"
import poli2 from "../assets/room/double_poli_lyuks/Rectangle 122.png"
import poli3 from "../assets/room/double_poli_lyuks/Rectangle 123.png"
import poli4 from "../assets/room/double_poli_lyuks/Rectangle 124.png"
import poli5 from "../assets/room/double_poli_lyuks/Rectangle 125.png"
import poli6 from "../assets/room/double_poli_lyuks/Rectangle 126.png"
import poli7 from "../assets/room/double_poli_lyuks/Rectangle 128.png"
import poli8 from "../assets/room/double_poli_lyuks/Rectangle 132.png"
import poli9 from "../assets/room/double_poli_lyuks/Rectangle 133.png"

// one standart 
import onest1 from "../assets/room/one_standart/Rectangle 120.png"
import onest2 from "../assets/room/one_standart/Rectangle 122.png"
import onest3 from "../assets/room/one_standart/Rectangle 123.png"
import onest4 from "../assets/room/one_standart/Rectangle 124.png"
import onest5 from "../assets/room/one_standart/Rectangle 125.png"
import onest6 from "../assets/room/one_standart/Rectangle 126.png"
import onest7 from "../assets/room/one_standart/Rectangle 134.png"

//one poli lyuks
import oneLy1 from "../assets/room/one_poli_lyuks/Rectangle 120.png"
import oneLy2 from "../assets/room/one_poli_lyuks/Rectangle 122.png"
import oneLy3 from "../assets/room/one_poli_lyuks/Rectangle 123.png"
import oneLy4 from "../assets/room/one_poli_lyuks/Rectangle 124.png"
import oneLy5 from "../assets/room/one_poli_lyuks/Rectangle 125.png"
import oneLy6 from "../assets/room/one_poli_lyuks/Rectangle 126.png"
import oneLy7 from "../assets/room/one_poli_lyuks/Rectangle 127.png"
import oneLy8 from "../assets/room/one_poli_lyuks/Rectangle 128.png"
import oneLy9 from "../assets/room/one_poli_lyuks/Rectangle 129.png"
import oneLy10 from "../assets/room/one_poli_lyuks/Rectangle 131.png"

// Lyuks

import lyuks1 from "../assets/room/lyuks/Rectangle 120.png"
import lyuks2 from "../assets/room/lyuks/Rectangle 122.png"
import lyuks3 from "../assets/room/lyuks/Rectangle 123.png"
import lyuks4 from "../assets/room/lyuks/Rectangle 124.png"
import lyuks5 from "../assets/room/lyuks/Rectangle 125.png"
import lyuks6 from "../assets/room/lyuks/Rectangle 126.png"

//apartament

import apartament_1 from "../assets/room/apartament/apartament_1.png"
import apartament_2 from "../assets/room/apartament/apartament_2.png"
import apartament_3 from "../assets/room/apartament/apartament_3.png"
import apartament_main from "../assets/room/apartament/apartament_main_14.png"


export const rooms = [
        {
            id: 1,
            img: double1,
            title: {
                en:"Double standard",
                ru:"Двухместный стандарт",
                uz:"Ikki kishilik standart"
            },
            price: 33,
            price_valuta:"400 000",
            description:{
                en:"This is a spacious and functional room designed to comfortably accommodate two guests. This room type is an ideal choice for couples and individual travelers who require comfortable accommodation at a reasonable price.",
                ru:"Это просторная и функциональная комната, предназначенная для комфортного проживания двух гостей. Этот тип номера является идеальным выбором для пар и индивидуальных путешественников, которым требуется комфортное проживание по разумной цене.",
                uz:"Bu ikki mehmon uchun qulay yashash uchun mo'ljallangan keng va funktsional xona. Ushbu turdagi xona o'rtacha narxda qulay yashashni talab qiladigan juftliklar va yakka sayohatchilar uchun ideal tanlovdir."
            },
            img_list:[
                {
                    id: 1,
                    url:double2
                },
                {
                    id: 2,
                    url:double3
                },
                {
                    id: 3,
                    url:double4
                },
                {
                    id: 4,
                    url:double5
                },
                {
                    id: 5,
                    url:double6
                },
            ],
            link: "https://kuula.co/share/5FRQj/collection/7Xmqz?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1",
            slug: uuidv4()
          },
          {
            id: 2,
            img: poli1,
            title: {
                en:"Double semi - deluxe",
                ru:"Двухместный полу - люкс",
                uz:"Ikki kishilik Junior Suite"
            },
            price: 41,
            price_valuta:"500 000",
            description:{
                en:"This is a spacious and comfortable room that offers guests coziness and luxury in one. This room category is ideal for couples and individual travelers who appreciate space and added amenities.",
                ru:"Это просторный и комфортабельный номер, который предлагает гостям уют и роскошь в одном. Эта категория номера идеально подходит для пар и индивидуальных путешественников, которые ценят пространство и добавленные удобства.",
                uz:"Bu mehmonlarga qulaylik va hashamatni taklif qiladigan keng va qulay xona. Ushbu toifadagi xonalar bo'sh joy va qo'shimcha qulayliklarni qadrlaydigan juftliklar va yolg'iz sayohatchilar uchun juda mos keladi."
            },
            img_list:[
                
                {
                    id: 1,
                    url:poli2
                },
                {
                    id: 2,
                    url:poli3
                },
                {
                    id: 3,
                    url:poli4
                },
                {
                    id: 4,
                    url:poli5
                },
                {
                    id: 5,
                    url:poli6
                },
                {
                    id: 6,
                    url:poli7
                },
                {
                    id: 7,
                    url:poli8
                },
                {
                    id: 8,
                    url:poli9
                },
            ],
            link: "https://kuula.co/share/5FW77/collection/7Xmv9?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1",
            slug: uuidv4()
          },
          {
            id: 3,
            img: onest1,
            title: {
                en:"Single standard",
                ru:"Одноместный стандарт",
                uz:"Bir kishilik standart"
            },
            price: 22,
            price_valuta:"250 000",
            description:{
                en:"This is a cozy and functional room designed for one guest. This room type is ideal for individual travelers who require comfortable and economical accommodation.",
                ru:"Это уютная и функциональная комната, предназначенная для одного гостя. Этот тип номера идеально подходит для индивидуальных путешественников, которым требуется комфортное и экономичное проживание.",
                uz:"Bu bitta mehmon uchun mo'ljallangan qulay va funktsional xona. Ushbu turdagi xona qulay va tejamkor turar joyni talab qiladigan yakka sayohatchilar uchun juda mos keladi."
            },
            img_list:[
                
                {
                    id: 1,
                    url:onest2
                },
                {
                    id: 2,
                    url:onest3
                },
                {
                    id: 3,
                    url:onest4
                },
                {
                    id: 4,
                    url:onest5
                },
                {
                    id: 5,
                    url:onest6
                },
                {
                    id: 6,
                    url:onest7
                },
            ],
            link: "https://kuula.co/share/collection/7Xmvm?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1",
            slug: uuidv4()
          },
          {
            id: 4,
            img: oneLy1,
            title: {
                en:"Single semi - suite",
                ru:"Одноместный полу - люкс",
                uz:"Bir kishilik Junior Suite"
            },
            price: 30,
            price_valuta:"350 000",
            description:{
                en:"This is a luxurious and spacious room designed for one guest who appreciates comfort and added amenities. This type of room provides the guest with maximum privacy and a luxurious living experience.",
                ru:"Это роскошный и просторный номер, предназначенный для одного гостя, который ценит комфорт и дополнительные удобства. Этот тип номера обеспечивает гостю максимальную приватность и роскошный опыт проживания.",
                uz:"Bu keng qamrovli qulayliklarni qadrlaydigan bitta mehmon uchun mo'ljallangan hashamatli va keng xona. Ushbu turdagi xona mehmonga maksimal xavfsizlik va hashamatli yashash tajribasini taqdim etadi."
            },
            img_list:[
                
                {
                    id: 1,
                    url:oneLy2
                },
                {
                    id: 2,
                    url:oneLy3
                },
                {
                    id: 3,
                    url:oneLy4
                },
                {
                    id: 4,
                    url:oneLy5
                },
                {
                    id: 5,
                    url:oneLy6
                },
                {
                    id: 6,
                    url:oneLy7
                },
                {
                    id: 7,
                    url:oneLy8
                },
                {
                    id: 8,
                    url:oneLy9
                },
                {
                    id: 9,
                    url:oneLy9
                },
                {
                    id:10,
                    url:oneLy10
                }
            ],
            link: "https://kuula.co/share/collection/7Xkfq?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1",
            slug: uuidv4()
          },
          {
            id: 5,
            img: apartament_main,
            title: {
                en:"apartment",
                ru:"апартамент",
                uz:"apartament"
            },
            price: 90,
            price_valuta:"1 100 000",
            description:{
                en:"An apartment is an excellent option for those who need long-term accommodation or prefer more space and amenities than traditional hotels. They offer fully furnished apartments with a hotel-style booking system, making them ideal for both business and leisure.",
                ru:"Апартамент — отличный вариант для тех, кто нуждается в долгосрочном размещении или предпочитает больше места и удобств, чем традиционные отели. Они предлагают полностью меблированные апартаменты с системой бронирования гостиничного типа, что делает их идеальными как для бизнеса, так и для отдыха.",
                uz:"Kvartira uzoq muddatli turar joyni izlayotgan yoki an'anaviy mehmonxonalarga qaraganda ko'proq kengroq joy va qulaylikni afzal ko'rganlar uchun ajoyib imkoniyatdir. Ular mehmonxona uslubida bron qilish tizimiga ega bo'lgan to'liq jihozlangan xonalarni taklif qilishadi, bu ularni biznes va dam olish uchun ideal qiladi."
            },
            img_list:[
                
                {
                    id: 1,
                    url:lyuks6
                },
                {
                    id: 2,
                    url:apartament_1
                },
                {
                    id: 3,
                    url:apartament_2
                },
                {
                    id: 4,
                    url:apartament_3
                },
                {
                    id: 5,
                    url:lyuks5
                },
            ],
            link: "https://kuula.co/share/collection/7Xkfl?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1",
            slug: uuidv4()
          },
          {
            id: 6,
            img: lyuks1,
            title: {
                en:"Lux",
                ru:"Люкс",
                uz:"Lyuks"
            },
            price: 70,
            price_valuta:"800 000",
            description:{
                en:"This is a sophisticated and luxurious accommodation option that offers guests the ultimate in comfort and spaciousness. This room type provides a high level of luxury and offerings, ideal for guests who want to enjoy elegance and sophistication during their stay.",
                ru:"Это изысканный и роскошный вариант размещения, предлагающий гостям максимальный комфорт и простор. Этот тип номера обеспечивает высокий уровень роскоши и предложений, идеально подходящий для гостей, которые хотят насладиться элегантностью и изысканностью во время пребывания.",
                uz:"Bu mehmonlarga maksimal qulaylik va imkoniyatlarni taklif qiladigan dabdabali va hashamatli turar joy variantidir. Ushbu turdagi xona yuqori darajadagi hashamat va qulayliklarni taqdim etadi, bu turar joy davomida  nafislikdan bahramand bo'lishni istagan mehmonlar uchun juda mos keladi."
            },
            img_list:[
                
                {
                    id: 1,
                    url:lyuks2
                },
                {
                    id: 2,
                    url:lyuks3
                },
                {
                    id: 3,
                    url:lyuks4
                },
                {
                    id: 4,
                    url:lyuks5
                },
                {
                    id: 5,
                    url:lyuks6
                },
            ],
            link: "https://kuula.co/share/collection/7Xmvq?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1",
            slug: uuidv4()
          },
]