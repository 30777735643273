import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: {
    open:false,
    link:"",
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal: (state, { payload }) => {
      state.modal = payload;
    },
  },
});

export const { setModal } = modalSlice.actions;

export default modalSlice.reducer;
