import React from 'react'
import { Link } from 'react-router-dom'
import TranslateText from '../../context/TranslateText'
import Seohelmet from '../../components/Helmet'

const NotFound = () => {
  return (
    <div className='bg-404 bg-right bg-cover w-full h-screen fixed top-0 z-50 overflow-hidden'>
      <Seohelmet title={"Kamilla hotel - Page not found"}/>

        <div className='container flex flex-col h-full justify-center'>
            <div className='max-w-[660px]'>
            <h1 className='text-[37px] text-[#383838] font-fira-sans font-medium leading-[52px] uppercase'><TranslateText dictionary={{
              en:"oops! this page is missing:",
              ru:"ой! эта страница отсутствует:",
              uz:"voy! bu sahifa etishmayapti:"
            }} /></h1>
            <p className='text-[#383838] text-[17px] font-medium'><TranslateText dictionary={{
              en:"Sorry, the page you are looking for cannot be found. it seems that the url you were trying to access is either incorrect or the page has been removed",
              ru:"К сожалению, страница, которую вы ищете, не найдена. кажется, что URL-адрес, к которому вы пытались получить доступ, либо неверен, либо страница была удалена",
              uz:"Kechirasiz, siz qidirayotgan sahifa topilmadi. siz kirmoqchi bo'lgan URL manzili noto'g'ri yoki sahifa o'chirilganga o'xshaydi"
            }} /></p>
            <button id='btn' className='m-auto mt-[30px] md:mt-[50px]  uppercase 2xl:text-[21px] xl:text-[18px] 2xl:w-[267px] xl:w-[176px] lg:w-[176px] w-[152px] 2xl:h-[51px] xl:h-[45px] h-[37px] border-2 border-solid border-[#EE9B00] bg-[#EE9B00] text-[white] hover:transition-[1s] transition-[1s] rounded-lg'><Link to={"/"}>back to home</Link></button>
            </div>
        </div>
    </div>
  )
}

export default NotFound
