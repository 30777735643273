import React, { useEffect } from 'react'
import Virtual from "../../assets/images/viirtual.png"
import Modal from '../../components/Modal'
import { rooms } from '../../data/rooms'
import { setModal } from '../../redux/modal.slice'
import { useDispatch, useSelector } from 'react-redux'
import TranslateText from '../../context/TranslateText'
import Seohelmet from '../../components/Helmet'
const VirTour = () => {
  const { modal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
    useEffect(() => {
      window.scrollTo(0,0)
    },[])
    
  return (
    <div>
      <Seohelmet title={"Kamilla hotel - Virtual tour"}/>
      {modal.open? <Modal/> : "" }
      
    <div className='container lg:mt-[160px] mt-[100px]'>
      <div className='uppercase md:px-[0] px-[10px] text-[#220901] font-fira-sans md:text-[51px] text-[28px] font-[400] md:my-14 my-[50px]'>
        <TranslateText dictionary={{
          en:"virtual tour of the rooms",
          ru:"виртуальный тур по помещениям",
          uz:"xonalarga virtual sayohat"
        }} />
        
      </div>
      <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-5 md:px-[0px] px-[10px] gap-[10px] mb-20'>
        {rooms.map(item=>(
          <div onClick={()=>dispatch(setModal({open:true,link:item.link}))} className={`relative cursor-pointer after:content-[' '] after:absolute after:w-full after:h-full  after:bg-[#000] after:top-0 after:z-0 z-10 after:opacity-40 after:rounded-[10px] after:hover:scale-[1.01]   `}>
            <img src={item.img} alt={item.img} className='w-full rounded-[10px] hover:scale-[1.01]  transition-[1s]'/>
            <img src={Virtual} alt="Virtual" className='hover:scale-[1.5] transition-all absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-10'/>
            <p className='absolute z-10 bottom-[2%] left-[3%] text-[white] text-[23px] font-manrope'><TranslateText dictionary={item.title} /></p>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

export default VirTour